import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { MEDIA_API_URL, MEDIA_URL } from "../../constantes";
import ReactDOM from 'react-dom';

import {
    Row,
    Button,
    CardBody,
    FormGroup,
    Input,
    Col,
} from "reactstrap";

import { useLocation, useNavigate } from "react-router-dom";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
    getPaginatedJobs, getPaginatedJobsUsersWithRange,
    getPaginatedJobsWithRange,
    getPaginatedJobUsers,
    getUserApplications
} from "../../services/JobServices";
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/Header";
import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import ReactPaginate from "react-paginate";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UserApplications() {

    const location = useLocation();
    const { user, connected, afterLogin } = useContext(AuthContext);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [status, setStatus] = useState('ACCEPTED');
    const [activeObject, setActiveObjet] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [appliedJobs, setAppliedJobs] = useState([]);
    const [job, setJob] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [shown, setShown] = useState(false);
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [newStatus, setNewStatus] = useState("");
    const [lastPage, setLastPage] = useState(1);
    const [nbElement, setNbElement] = useState('');

/*     const handleViewApplication = async () => {
        let job_user_id = activeObject.id;
        console.log(job_user_id);
        await changeApplicationStatus(job_user_id, "IN_PROGRESS");

        gotoDetails(job_user_id)

    } */

    const gotoDetails = (user_id, job_id) => {
        navigate('/application-details',
        { state: { jobId: job_id, userId: user_id } });
    }

/*     const handleChangeApplicationStatus = async () => {
        let job_user_id = activeObject.id;
        console.log(job_user_id);
        console.log(status);
        let ju = await changeApplicationStatus(job_user_id, status);
        const newAppliedJobs = appliedJobs.map(item => {
            if (item.id == ju.id) {
                return ju;
            } else {
                return item;
            }
        });

        setOpenStatusModal(false);
        setAppliedJobs(newAppliedJobs);
    }; */

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        console.log(requestedPage)
        let posts = await getPaginatedJobUsers(user?.id, requestedPage, nbElement);
        console.log("les posts");
        console.log(posts);
        setAppliedJobs(posts.data);
        setLastPage(posts.last_page);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        let posts = await getPaginatedJobsUsersWithRange(user?.id, parseInt(nb));
        console.log(posts)
        setAppliedJobs(posts.data);
        setLastPage(posts.last_page);
    }

    useEffect(() => {
        const user_id = user?.id;
        const retrieveUserApplications = async () => {
            let data = await getUserApplications(user_id);
            let arr = Object.values(data);
            console.log(' les donness')
            console.log(data)
            setAppliedJobs(data.data);
        }

        if (user) {
            retrieveUserApplications();
        }

    }, [user]);


    const handleStatusModal = () => {
        setOpenStatusModal(!openStatusModal);
    };

    const handleViewModal = () => {
        setOpenViewModal(!openViewModal);
    };

    const handleChangeStatus = (event) => {
        setNewStatus(event.target.value);
    };

    return (
        <div >
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
{/*             <Dialog open={openStatusModal} onClose={handleStatusModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Change Application State</DialogTitle>
                    <DialogContent>
                        <CardBody>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                        <div className="modal-body">
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-address"
                                            >
                                                Select state
                                            </label>
                                        </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                        <div className="modal-body">
                                            <Input
                                                className="form-control-alternative"
                                                type="select"
                                                onChange={handleChangeStatus}
                                                id="exampleSelect"
                                                style={{width: '160px', height: '40px'}}>
                                                <option value={"ACCEPTED"}>ACCEPT</option>
                                                <option value={"REJECTED"}>REJECT</option>
                                            </Input>
                                        </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleStatusModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleChangeApplicationStatus}
                            color="primary"
                            type="button"
                        >
                            Change
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={openViewModal} onClose={handleViewModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">View User Application </DialogTitle>
                    <DialogContent>
                        <CardBody>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <div className="modal-body">
                                                <p>Are you sure you want to view this application ?</p>
                                                <p>This will automatically start the treatment of this file and the user could not change his application</p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleViewModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleViewApplication}
                            color="primary"
                            type="button"
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </form>
            </Dialog> */}
            <div id="container">

                <Header />

                <Aside />


                <div id="main-content">
                    <div className="wrapper">
                        <h3><i className="fa fa-angle-right"></i>Applications for {job?.title}</h3>
                        <div className="row mb">
                            <div className="content-panel">
                                <div className="adv-table">

                                    <table cellPadding="0" cellSpacing="0" border="0" className="display table table-bordered" id="hidden-table-info">
                                        <thead>
                                            <tr>
                                                <th>Username</th>
                                                <th>Job Title</th>
                                                <th>Status</th>
                                                <th>No Pending Requests</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                appliedJobs?.map((item) =>
                                                    <tr key={item.id} className="gradeX">
                                                        <td>{item.user_name}</td>
                                                        <td>{item.job_title}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.job_user_requests}</td>
                                                        <td><button className="btn logout btn-xs" style={{ marginRight: '5px' }} onClick={() => { gotoDetails(user?.id, item.job_id)}} >see details</button>
                                                            {/* <button className="btn btn-secondary btn-xs ms-1" onClick={() => { setActiveObjet(item);setOpenStatusModal(true) }}>Change status</button> */}
                                                            </td>
                                                    </tr>

                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row mt">

                            <div className="col-md-4">
                                <form>
                                    <FormGroup className="mb-0" style={{width: "100px"}}>
                                        <select className="form-control" onChange={(e) => {
                                            // console.log(e.target.value);
                                            handleSearch(e)
                                        }}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25" selected>25</option>
                                        </select>
                                    </FormGroup>
                                </form>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-2">
                                <nav aria-label="...">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={'...'}
                                        pageCount={lastPage}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={6}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-end mb-0"}
                                        pageClassName={"page-item"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        pageLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}

                                    />
                                </nav>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <Footer /> */}

            </div>
        </div>
    );
}

export default UserApplications;