import HomeDefault from "./pages/HomeDefault";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Job from "./pages/Jobs/Job";
import Service from "./pages/Services/Service";
import ServiceDetails from "./pages/Services/ServiceDetails";
import JobDetails from "./pages/Jobs/JobDetails";
import VerifyEmail from "./pages/VerifyEmail";
import Profile from "./pages/User/Profile";
import ApplicationDetails from "./pages/User/ApplicationDetails";
import UserApplications from "./pages/User/UserApplications";
import ResetPassword from "./pages/ResetPassword";
import EmailVerified from "./pages/EmailVerified";

const routes = [
    {
        component: <HomeDefault />,
        path: '/',
    },
    {
        component: <Login />,
        path: '/login',
    },
    {
        component: <VerifyEmail />,
        path: '/verify-email',
    },
    {
        component: <EmailVerified />,
        path: '/email-verify-success',
    },
    {
        component: <Register />,
        path: '/register',
    },
    {
        component: <ForgotPassword />,
        path: '/forgot-password',
    },
    {
        component: <ResetPassword />,
        path: '/reset-password',
    },
    {
        component: <Job />,
        path: '/jobs',
    },{
        component: <JobDetails />,
        path: '/job-details',
    },
    {
        component: <Service />,
        path: '/services',
    },
    {
        component: <ServiceDetails />,
        path: '/services-details',
    },
    {
        component: <Home />,
        path: '/account',
    },
    {
        component: <Profile />,
        path: '/profile',
    },
    {
        component: <ApplicationDetails />,
        path: '/application-details',
    },
    {
        component: <UserApplications />,
        path: '/user-applications',
    }
];
export default routes;

