import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {MEDIA_URL_AVATAR} from "../constantes";
import {AuthContext} from "../context/AuthContext";
import {updateAvatar, updateInfos} from "../services/UserServices";

function Aside() {

    const {user, connected, afterLogin} = useContext(AuthContext);
    const navigate = useNavigate();

    const [isActiveJob, setIsActiveJob] = useState(false);
    const [isActiveService, setIsActiveService] = useState(false);
    const [isActiveCategory, setIsActiveCategory] = useState(false);
    const [isActiveProduct, setIsActiveProduct] = useState(false);
    const [isActiveUser, setIsActiveUser] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [image, setImage] = useState(null);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const {register, handleSubmit} = useForm();
    //const [alertType, setAlertType] = useState("success");
    //const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {

    }, []);

    const handleIsActiveJob = () => {
        setIsActiveJob(!isActiveJob);
    };

    const handleIsActiveService = () => {
        setIsActiveService(!isActiveService);
    };

    const handleIsActiveCategory = () => {
        setIsActiveCategory(!isActiveCategory);
    };

    const handleIsActiveProduct = () => {
        setIsActiveProduct(!isActiveProduct);
    };

    const handleIsActiveUser = () => {
        setIsActiveUser(!isActiveUser);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleUploadImage = (event) => {
        const uplaodImage = event.target.files[0];
        const fileExtension = event.target.files[0].name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png"];
        if (!allowedFileTypes.includes(fileExtension)) {
            setImageUrl('');
            setImage(null);
            event.target.value = null;
            window.alert(`File not supported. Please choose a file with one of the allowed file types :  ${allowedFileTypes.join(", ")}`);
        } else {
            const imageUrl = URL.createObjectURL(uplaodImage);
            setImageUrl(imageUrl);
            setImage(uplaodImage);
        }
    }

    const completeInfos = async () => {
        console.log("on est dans l'image");
        try {
            const result = await updateAvatar(user?.id, image);
            setAlertMessage(result.message);
            setAlertType("success");
            handleOpenAlert();
            afterLogin();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const filledFormInfos = async (data) => {
        console.log("les donness du formulaire")
        console.log(data)
        try {
            const result = await updateInfos(user?.id, data);
            console.log(result);
            setAlertMessage(result.message);
            setAlertType("success");
            handleOpenAlert();
            afterLogin();
            const form = document.getElementById('update-profile');
            form.reset();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    return (
        <div>
            <aside>
                <div id="sidebar" className="nav-collapse ">

                    <ul className="sidebar-menu" id="nav-accordion">
                        {user !== null ?
                            <p className="centered"><a data-bs-toggle="modal"
                                                       data-bs-target="#myAvatarModal"><img
                                src={MEDIA_URL_AVATAR + user?.avatar} className="img-circle" width="100"/></a></p> : ''}
                        <h5 className="centered">{user?.username}</h5>
                        <li className="mt">
                            <a className="active" href="/profile">
                                <i className="fa fa-dashboard"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li className="sub-menu">
                            <a href="/user-applications" onClick={handleIsActiveJob}>
                                <i className="fa fa-desktop"></i>
                                <span>My Applications</span>
                            </a>
                        </li>
                        {/*
                        <li className="sub-menu">
                            <a href="#" onClick={handleIsActiveService}>
                                <i className="fa fa-cogs"></i>
                                <span>Services</span>
                            </a>
                            <ul className="sub" style={{display : `${isActiveService ? 'block' : ''}`}}>
                                <li><a href="/create-service">Create</a></li>
                                <li><a href="/all-services">View Service</a></li>
                            </ul>
                        </li>
                        <li className="sub-menu">
                            <a href="#" onClick={handleIsActiveProduct}>
                                <i className="fa fa-book"></i>
                                <span>Products</span>
                            </a>
                            <ul className="sub" style={{display : `${isActiveProduct ? 'block' : ''}`}}>
                                <li><a href="/create-product">Create</a></li>
                                <li><a href="/all-products">View Product</a></li>
                            </ul>
                        </li>
                        <li className="sub-menu">
                            <a href="#" onClick={handleIsActiveUser}>
                                <i className="fa fa-tasks"></i>
                                <span>Users</span>
                            </a>
                            <ul className="sub" style={{display : `${isActiveUser ? 'block' : ''}`}}>
                                <li><a href="#">View Users</a></li>
                            </ul>
                        </li> */}

                        {/* <li className="sub-menu">
                            <a href="#">
                                <i className="fa fa-th"></i>
                                <span>Data Tables</span>
                            </a>
                            <ul className="sub">
                                <li><a href="basic_table.html">Basic Table</a></li>
                                <li><a href="responsive_table.html">Responsive Table</a></li>
                                <li><a href="advanced_table.html">Advanced Table</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="inbox.html">
                                <i className="fa fa-envelope"></i>
                                <span>Mail </span>
                                <span className="label label-theme pull-right mail-info">2</span>
                            </a>
                        </li>
                        <li className="sub-menu">
                            <a href="#">
                                <i className=" fa fa-bar-chart-o"></i>
                                <span>Charts</span>
                            </a>
                            <ul className="sub">
                                <li><a href="morris.html">Morris</a></li>
                                <li><a href="chartjs.html">Chartjs</a></li>
                                <li><a href="flot_chart.html">Flot Charts</a></li>
                                <li><a href="xchart.html">xChart</a></li>
                            </ul>
                        </li>
                        <li className="sub-menu">
                            <a href="#">
                                <i className="fa fa-comments-o"></i>
                                <span>Chat Room</span>
                            </a>
                            <ul className="sub">
                                <li><a href="lobby.html">Lobby</a></li>
                                <li><a href="chat_room.html"> Chat Room</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="google_maps.html">
                                <i className="fa fa-map-marker"></i>
                                <span>Google Maps </span>
                            </a>
                        </li> */}
                    </ul>

                </div>

                <div className="modal" id="myAvatarModal">
                    <div
                        className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-dialog">
                            <div className="modal-content" style={{width: '500px'}}>
                                <div
                                    className="modal-header ">
                                    <h3 className="modal-title text-white">
                                        Change
                                        your avatar image</h3>
                                    <button type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type="file"
                                           width="20px"
                                           className="form-control"
                                           name="avatar"
                                           onChange={handleUploadImage}
                                    /><br/>
                                    <center><img
                                        src={imageUrl}
                                        className="rounded-circle"
                                        width="20%"
                                        style={{cursor: 'pointer'}}/>
                                    </center>
                                </div>

                                <div className="modal-footer">
                                    <button type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="button"
                                            className="btn btn-primary save"
                                            onClick={completeInfos}
                                            data-bs-dismiss="modal"> Save
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal" id="myProfileModal">
                    <div
                        className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-dialog">
                            <div className="modal-content" style={{width: '500px'}}>
                                <div
                                    className="modal-header ">
                                    <h3 className="modal-title text-white">
                                        Change
                                        your avatar image</h3>
                                    <button type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form id="update-profile" onSubmit={handleSubmit(filledFormInfos)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">First
                                                        Name</label>
                                                    <input type="text" className="form-control"
                                                           name="first_name"  {...register('first_name')}
                                                           defaultValue={user?.first_name}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Last Name</label>
                                                    <input type="text" className="form-control" name="last_name"
                                                           defaultValue={user?.last_name}
                                                           {...register('last_name')} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Email <span
                                                        style={{color: 'red'}}>*</span></label>
                                                    <input type="text" className="form-control"
                                                           defaultValue={user?.email}
                                                           {...register('email')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Mobile
                                                        phone</label>
                                                    <input type="text" className="form-control" name="phone"
                                                           defaultValue={user?.phone}
                                                           {...register('phone')} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">User Name <span
                                                        style={{color: 'red'}}>*</span></label>
                                                    <input type="text" className="form-control" name="username"
                                                           defaultValue={user?.username}
                                                           {...register('username')} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                            <button type="button"
                                                    className="btn btn-secondary"
                                                    data-bs-dismiss="modal">Close
                                            </button>
                                            </div>
                                            <div className="col-md-6">
                                            <button type="submit"
                                                    className="btn btn-primary save"
                                                    data-bs-dismiss="modal"
                                            > Save
                                            </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
);
}

export default Aside;