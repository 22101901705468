import React from "react";
import Testimonials from "./Testimonials";
import GlobalHeader from "../components/GlobalHeader";
import GlobalFooter from "../components/GlobalFooter";

function HomeDefault() {

    return (
        <>
            <div style={{backgroundColor: "#fff"}}>
                <GlobalHeader />

                <section id="hero" className="d-flex align-items-center" style={{marginTop: '5%' }}>

                    <div className="container" data-aos="zoom-out" data-aos-delay="100">
                        <div className="row">
                            <div className="col-xl-6">
                                <h1>Bettter digitalize your company</h1>
                                <h2>Get support to optimize your digital communication.</h2>
                                <a href="#about" className="btn-get-started scrollto">Get Started</a>
                            </div>
                        </div>
                    </div>

                </section>

                <main id="main">

                    {/*<section id="clients" className="clients">*/}
                    {/*    <div className="container" data-aos="zoom-in">*/}

                    {/*        <div className="clients-slider swiper">*/}
                    {/*            <div className="swiper-wrapper align-items-center">*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-1.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-2.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-3.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-4.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-5.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-6.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-7.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*                <div className="swiper-slide"><img src="client/img/clients/client-8.png" className="img-fluid"*/}
                    {/*                                               alt=""/></div>*/}
                    {/*            </div>*/}
                    {/*            <div className="swiper-pagination"></div>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</section>*/}
                    <section id="clients" className="clients">
                        <div className="container" data-aos="zoom-in" style={{marginLeft : "30%"}}>

                            <div className="clients-slider swiper">
                                <div className="swiper-wrapper align-items-center">
                                    <div className="swiper-slide"><img src="client/img/logo.jpeg" className="img-fluid"
                                                                   alt=""/></div>

                                </div>
                            </div>

                        </div>
                    </section>

                    <section id="about" className="about section-bg">
                        <div className="container" data-aos="fade-up">

                            <div className="row no-gutters">
                                <div className="content col-xl-5 d-flex align-items-stretch">
                                    <div className="content">
                                        <h3>Voluptatem dignissimos provident quasi</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor
                                            incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in
                                            reprehenderit
                                        </p>
                                        <a href="#" className="about-btn"><span>About us</span> <i
                                            className="bx bx-chevron-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-xl-7 d-flex align-items-stretch">
                                    <div className="icon-boxes d-flex flex-column justify-content-center">
                                        <div className="row">
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                                <i className="bx bx-receipt"></i>
                                                <h4>Corporis voluptates sit</h4>
                                                <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris
                                                    nisi
                                                    ut aliquip</p>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                                <i className="bx bx-cube-alt"></i>
                                                <h4>Ullamco laboris nisi</h4>
                                                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                                                    officia
                                                    deserunt</p>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                                <i className="bx bx-images"></i>
                                                <h4>Labore consequatur</h4>
                                                <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores
                                                    omnis
                                                    facere</p>
                                            </div>
                                            <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                                                <i className="bx bx-shield"></i>
                                                <h4>Beatae veritatis</h4>
                                                <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat
                                                    pacta</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section id="counts" className="counts">
                        <div className="container" data-aos="fade-up">

                            <div className="row">

                                <div className="col-lg-4 col-md-6">
                                    <div className="count-box">
                                        <i className="bi bi-emoji-smile"></i>
                                        <span data-purecounter-start="0" data-purecounter-end="232"
                                              data-purecounter-duration="1" className="purecounter">23</span>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
                                    <div className="count-box">
                                        <i className="bi bi-journal-richtext"></i>
                                        <span>12</span>
                                        <p>Projects</p>
                                    </div>
                                </div>

                                {/*<div className="col-lg-3 col-md-6 mt-5 mt-lg-0">*/}
                                {/*    <div className="count-box">*/}
                                {/*        <i className="bi bi-headset"></i>*/}
                                {/*        <span data-purecounter-start="0" data-purecounter-end="1463"*/}
                                {/*              data-purecounter-duration="1" className="purecounter"></span>*/}
                                {/*        <p>Hours Of Support</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="col-lg-4 col-md-6 mt-5 mt-lg-0">
                                    <div className="count-box">
                                        <i className="bi bi-people"></i>
                                        <span>5</span>
                                        <p>Hard Workers</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>

                    {/*<section id="tabs" className="tabs">*/}
                    {/*    <div className="container" data-aos="fade-up">*/}

                    {/*        <ul className="nav nav-tabs row d-flex">*/}
                    {/*            <li className="nav-item col-3">*/}
                    {/*                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">*/}
                    {/*                    <i className="ri-gps-line"></i>*/}
                    {/*                    <h4 className="d-none d-lg-block">Modi sit est dela pireda nest</h4>*/}
                    {/*                </a>*/}
                    {/*            </li>*/}
                    {/*            <li className="nav-item col-3">*/}
                    {/*                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">*/}
                    {/*                    <i className="ri-body-scan-line"></i>*/}
                    {/*                    <h4 className="d-none d-lg-block">Unde praesenti mara setra le</h4>*/}
                    {/*                </a>*/}
                    {/*            </li>*/}
                    {/*            <li className="nav-item col-3">*/}
                    {/*                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">*/}
                    {/*                    <i className="ri-sun-line"></i>*/}
                    {/*                    <h4 className="d-none d-lg-block">Pariatur explica nitro dela</h4>*/}
                    {/*                </a>*/}
                    {/*            </li>*/}
                    {/*            <li className="nav-item col-3">*/}
                    {/*                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">*/}
                    {/*                    <i className="ri-store-line"></i>*/}
                    {/*                    <h4 className="d-none d-lg-block">Nostrum qui dile node</h4>*/}
                    {/*                </a>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}

                    {/*        <div className="tab-content">*/}
                    {/*            <div className="tab-pane active show" id="tab-1">*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up"*/}
                    {/*                         data-aos-delay="100">*/}
                    {/*                        <h3>Voluptatem dignissimos provident quasi corporis voluptates sit*/}
                    {/*                            assumenda.</h3>*/}
                    {/*                        <p className="fst-italic">*/}
                    {/*                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                            tempor incididunt ut labore et dolore*/}
                    {/*                            magna aliqua.*/}
                    {/*                        </p>*/}
                    {/*                        <ul>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut*/}
                    {/*                                aliquip ex*/}
                    {/*                                ea commodo consequat.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Duis aute irure dolor in*/}
                    {/*                                reprehenderit in voluptate velit.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut*/}
                    {/*                                aliquip ex*/}
                    {/*                                ea commodo consequat. Duis aute irure dolor in reprehenderit in*/}
                    {/*                                voluptate trideta storacalaperda mastiro dolore eu fugiat nulla*/}
                    {/*                                pariatur.*/}
                    {/*                            </li>*/}
                    {/*                        </ul>*/}
                    {/*                        <p>*/}
                    {/*                            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure*/}
                    {/*                            dolor in reprehenderit in voluptate*/}
                    {/*                            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint*/}
                    {/*                            occaecat*/}
                    {/*                            cupidatat non proident, sunt in*/}
                    {/*                            culpa qui officia deserunt mollit anim id est laborum*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up"*/}
                    {/*                         data-aos-delay="200">*/}
                    {/*                        <img src="client/img/tabs-1.jpg" alt="" className="img-fluid"/>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="tab-pane" id="tab-2">*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">*/}
                    {/*                        <h3>Neque exercitationem debitis soluta quos debitis quo mollitia officia*/}
                    {/*                            est</h3>*/}
                    {/*                        <p>*/}
                    {/*                            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure*/}
                    {/*                            dolor in reprehenderit in voluptate*/}
                    {/*                            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint*/}
                    {/*                            occaecat*/}
                    {/*                            cupidatat non proident, sunt in*/}
                    {/*                            culpa qui officia deserunt mollit anim id est laborum*/}
                    {/*                        </p>*/}
                    {/*                        <p className="fst-italic">*/}
                    {/*                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                            tempor incididunt ut labore et dolore*/}
                    {/*                            magna aliqua.*/}
                    {/*                        </p>*/}
                    {/*                        <ul>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut*/}
                    {/*                                aliquip ex*/}
                    {/*                                ea commodo consequat.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Duis aute irure dolor in*/}
                    {/*                                reprehenderit in voluptate velit.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Provident mollitia neque*/}
                    {/*                                rerum*/}
                    {/*                                asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut*/}
                    {/*                                aliquip ex*/}
                    {/*                                ea commodo consequat. Duis aute irure dolor in reprehenderit in*/}
                    {/*                                voluptate trideta storacalaperda mastiro dolore eu fugiat nulla*/}
                    {/*                                pariatur.*/}
                    {/*                            </li>*/}
                    {/*                        </ul>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-lg-6 order-1 order-lg-2 text-center">*/}
                    {/*                        <img src="client/img/tabs-2.jpg" alt="" className="img-fluid"/>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="tab-pane" id="tab-3">*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">*/}
                    {/*                        <h3>Voluptatibus commodi ut accusamus ea repudiandae ut autem dolor ut*/}
                    {/*                            assumenda</h3>*/}
                    {/*                        <p>*/}
                    {/*                            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure*/}
                    {/*                            dolor in reprehenderit in voluptate*/}
                    {/*                            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint*/}
                    {/*                            occaecat*/}
                    {/*                            cupidatat non proident, sunt in*/}
                    {/*                            culpa qui officia deserunt mollit anim id est laborum*/}
                    {/*                        </p>*/}
                    {/*                        <ul>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut*/}
                    {/*                                aliquip ex*/}
                    {/*                                ea commodo consequat.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Duis aute irure dolor in*/}
                    {/*                                reprehenderit in voluptate velit.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Provident mollitia neque*/}
                    {/*                                rerum*/}
                    {/*                                asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.*/}
                    {/*                            </li>*/}
                    {/*                        </ul>*/}
                    {/*                        <p className="fst-italic">*/}
                    {/*                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                            tempor incididunt ut labore et dolore*/}
                    {/*                            magna aliqua.*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-lg-6 order-1 order-lg-2 text-center">*/}
                    {/*                        <img src="client/img/tabs-3.jpg" alt="" className="img-fluid"/>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="tab-pane" id="tab-4">*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">*/}
                    {/*                        <h3>Omnis fugiat ea explicabo sunt dolorum asperiores sequi inventore*/}
                    {/*                            rerum</h3>*/}
                    {/*                        <p>*/}
                    {/*                            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure*/}
                    {/*                            dolor in reprehenderit in voluptate*/}
                    {/*                            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint*/}
                    {/*                            occaecat*/}
                    {/*                            cupidatat non proident, sunt in*/}
                    {/*                            culpa qui officia deserunt mollit anim id est laborum*/}
                    {/*                        </p>*/}
                    {/*                        <p className="fst-italic">*/}
                    {/*                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                    {/*                            tempor incididunt ut labore et dolore*/}
                    {/*                            magna aliqua.*/}
                    {/*                        </p>*/}
                    {/*                        <ul>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut*/}
                    {/*                                aliquip ex*/}
                    {/*                                ea commodo consequat.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Duis aute irure dolor in*/}
                    {/*                                reprehenderit in voluptate velit.*/}
                    {/*                            </li>*/}
                    {/*                            <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut*/}
                    {/*                                aliquip ex*/}
                    {/*                                ea commodo consequat. Duis aute irure dolor in reprehenderit in*/}
                    {/*                                voluptate trideta storacalaperda mastiro dolore eu fugiat nulla*/}
                    {/*                                pariatur.*/}
                    {/*                            </li>*/}
                    {/*                        </ul>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-lg-6 order-1 order-lg-2 text-center">*/}
                    {/*                        <img src="client/img/tabs-4.jpg" alt="" className="img-fluid"/>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</section>*/}

                    <section id="services" className="services section-bg ">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>Services</h2>
                                <p>We can offer to you, different services related to computer science.</p>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                        <i className="bi bi-briefcase"></i>
                                        <h4><a href="#">Web site</a></h4>
                                        <p>We accompany you in the creation and hosting of your websites: Availability of personalized domain names.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4 mt-md-0">
                                    <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                        <i className="bi bi-card-checklist"></i>
                                        <h4><a href="#">UI & UX DESIGN</a></h4>
                                        <p>We offer a range of connected products to take advantage of the advances in technology on the Design.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4 mt-md-0">
                                    <div className="icon-box" data-aos="fade-up" data-aos-delay="500">
                                        <i className="bi bi-binoculars"></i>
                                        <h4><a href="#">Application development</a></h4>
                                        <p>We support you in the design and development of your applications:
                                            (1) Writing of specifications,
                                            (2) Design and development of applications and solutions for IoT,
                                            (3) Design and deployment of computer networks</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4 mt-md-0">
                                    <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                        <i className="bi bi-calendar4-week"></i>
                                        <h4><a href="#">Training and internship</a></h4>
                                        <p>We offer a range of training courses tailored to your IT and data management needs.
                                            Software: Python for data science,
                                            SAS; MS Access DBMS, PostgreSQL and PHP; to name a few.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4 mt-md-0">
                                    <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                        <i className="bi bi-bar-chart"></i>
                                        <h4><a href="#">E-COMMERCE</a></h4>
                                        <p>You can edit and use this template for your websites. Please tell your friends about Tooplate. Thank you for visiting our website.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 mt-4 mt-md-0">
                                    <div className="icon-box" data-aos="fade-up" data-aos-delay="600">
                                        <i className="bi bi-brightness-high"></i>
                                        <h4><a href="#">SOCIAL MEDIA</a></h4>
                                        <p>We can permit you to have the best communication on the social media</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>


                    <section id="products" className="portfolio">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>Products</h2>
                                <p>Many of ours products are avalaible for you. Select an item and access the product pages details.</p>
                            </div>

                            <div className="row" data-aos="fade-up" data-aos-delay="100">
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <ul id="portfolio-flters">
                                        <li data-filter="*" className="filter-active">All</li>
                                        <li data-filter=".filter-app">App</li>
                                        <li data-filter=".filter-card">Card</li>
                                        <li data-filter=".filter-web">Web</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/portfolio/portfolio-1.jpg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>App 1</h4>
                                            <p>App</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/portfolio/portfolio-1.jpg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="App 1"><i
                                                    className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/product/neverlost.jpeg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>Web 3</h4>
                                            <p>Web</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/product/neverlost.jpeg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="Web 3"><i
                                                    className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/portfolio/portfolio-3.jpg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>App 2</h4>
                                            <p>App</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/portfolio/portfolio-3.jpg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="App 2"><i
                                                    className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/portfolio/portfolio-4.jpg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>Card 2</h4>
                                            <p>Card</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/portfolio/portfolio-4.jpg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="Card 2"><i className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/product/neverlost1.jpeg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>Web 2</h4>
                                            <p>Web</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/product/neverlost1.jpeg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="Web 2"><i
                                                    className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/portfolio/portfolio-6.jpg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>App 3</h4>
                                            <p>App</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/portfolio/portfolio-6.jpg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="App 3"><i
                                                    className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/portfolio/portfolio-7.jpg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>Card 1</h4>
                                            <p>Card</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/portfolio/portfolio-7.jpg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="Card 1"><i className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/portfolio/portfolio-8.jpg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>Card 3</h4>
                                            <p>Card</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/portfolio/portfolio-8.jpg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="Card 3"><i className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                    <div className="portfolio-wrap">
                                        <img src="client/img/product/neverlost.jpeg" className="img-fluid" alt=""/>
                                        <div className="portfolio-info">
                                            <h4>Web 3</h4>
                                            <p>Web</p>
                                            <div className="portfolio-links">
                                                <a href="client/img/product/neverlost.jpeg"
                                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                                   title="Web 3"><i
                                                    className="bx bx-plus"></i></a>
                                                <a href="portfolio-details.html" title="More Details"><i
                                                    className="bx bx-link"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>

                    {/*<Testimonials/>*/}

                    {/*<section id="pricing" className="pricing section-bg">*/}
                    {/*    <div className="container" data-aos="fade-up">*/}

                    {/*        <div className="section-title">*/}
                    {/*            <h2>Pricing</h2>*/}
                    {/*            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum*/}
                    {/*                quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui*/}
                    {/*                impedit suscipit alias ea.</p>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}

                    {/*            <div className="col-lg-4 col-md-6">*/}
                    {/*                <div className="box" data-aos="fade-up" data-aos-delay="100">*/}
                    {/*                    <h3>Free</h3>*/}
                    {/*                    <h4><sup>$</sup>0<span> / month</span></h4>*/}
                    {/*                    <ul>*/}
                    {/*                        <li>Aida dere</li>*/}
                    {/*                        <li>Nec feugiat nisl</li>*/}
                    {/*                        <li>Nulla at volutpat dola</li>*/}
                    {/*                        <li className="na">Pharetra massa</li>*/}
                    {/*                        <li className="na">Massa ultricies mi</li>*/}
                    {/*                    </ul>*/}
                    {/*                    <div className="btn-wrap">*/}
                    {/*                        <a href="#" className="btn-buy">Buy Now</a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-lg-4 col-md-6 mt-4 mt-md-0">*/}
                    {/*                <div className="box featured" data-aos="fade-up" data-aos-delay="200">*/}
                    {/*                    <h3>Business</h3>*/}
                    {/*                    <h4><sup>$</sup>19<span> / month</span></h4>*/}
                    {/*                    <ul>*/}
                    {/*                        <li>Aida dere</li>*/}
                    {/*                        <li>Nec feugiat nisl</li>*/}
                    {/*                        <li>Nulla at volutpat dola</li>*/}
                    {/*                        <li>Pharetra massa</li>*/}
                    {/*                        <li className="na">Massa ultricies mi</li>*/}
                    {/*                    </ul>*/}
                    {/*                    <div className="btn-wrap">*/}
                    {/*                        <a href="#" className="btn-buy">Buy Now</a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">*/}
                    {/*                <div className="box" data-aos="fade-up" data-aos-delay="300">*/}
                    {/*                    <h3>Developer</h3>*/}
                    {/*                    <h4><sup>$</sup>29<span> / month</span></h4>*/}
                    {/*                    <ul>*/}
                    {/*                        <li>Aida dere</li>*/}
                    {/*                        <li>Nec feugiat nisl</li>*/}
                    {/*                        <li>Nulla at volutpat dola</li>*/}
                    {/*                        <li>Pharetra massa</li>*/}
                    {/*                        <li>Massa ultricies mi</li>*/}
                    {/*                    </ul>*/}
                    {/*                    <div className="btn-wrap">*/}
                    {/*                        <a href="#" className="btn-buy">Buy Now</a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</section>*/}

                    {/*<section id="faq" className="faq">*/}
                    {/*    <div className="container" data-aos="fade-up">*/}

                    {/*        <div className="section-title">*/}
                    {/*            <h2>Frequently Asked Questions</h2>*/}
                    {/*        </div>*/}

                    {/*        <ul className="faq-list accordion" data-aos="fade-up">*/}

                    {/*            <li>*/}
                    {/*                <a data-bs-toggle="collapse" className="collapsed" data-bs-target="#faq1">Non*/}
                    {/*                    consectetur a*/}
                    {/*                    erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show"></i><i*/}
                    {/*                        className="bx bx-x icon-close"></i></a>*/}
                    {/*                <div id="faq1" className="collapse" data-bs-parent=".faq-list">*/}
                    {/*                    <p>*/}
                    {/*                        Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus*/}
                    {/*                        laoreet non curabitur gravida. Venenatis lectus magna fringilla urna*/}
                    {/*                        porttitor*/}
                    {/*                        rhoncus dolor purus non.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <a data-bs-toggle="collapse" data-bs-target="#faq2" className="collapsed">Feugiat*/}
                    {/*                    scelerisque varius morbi enim nunc faucibus a pellentesque? <i*/}
                    {/*                        className="bx bx-chevron-down icon-show"></i><i*/}
                    {/*                        className="bx bx-x icon-close"></i></a>*/}
                    {/*                <div id="faq2" className="collapse" data-bs-parent=".faq-list">*/}
                    {/*                    <p>*/}
                    {/*                        Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id*/}
                    {/*                        interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus*/}
                    {/*                        scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper*/}
                    {/*                        dignissim.*/}
                    {/*                        Mauris ultrices eros in cursus turpis massa tincidunt dui.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <a data-bs-toggle="collapse" data-bs-target="#faq3" className="collapsed">Dolor sit*/}
                    {/*                    amet*/}
                    {/*                    consectetur adipiscing elit pellentesque habitant morbi? <i*/}
                    {/*                        className="bx bx-chevron-down icon-show"></i><i*/}
                    {/*                        className="bx bx-x icon-close"></i></a>*/}
                    {/*                <div id="faq3" className="collapse" data-bs-parent=".faq-list">*/}
                    {/*                    <p>*/}
                    {/*                        Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci.*/}
                    {/*                        Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet*/}
                    {/*                        nisl*/}
                    {/*                        suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis*/}
                    {/*                        convallis*/}
                    {/*                        convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi*/}
                    {/*                        quis*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <a data-bs-toggle="collapse" data-bs-target="#faq4" className="collapsed">Ac odio*/}
                    {/*                    tempor*/}
                    {/*                    orci dapibus. Aliquam eleifend mi in nulla? <i*/}
                    {/*                        className="bx bx-chevron-down icon-show"></i><i*/}
                    {/*                        className="bx bx-x icon-close"></i></a>*/}
                    {/*                <div id="faq4" className="collapse" data-bs-parent=".faq-list">*/}
                    {/*                    <p>*/}
                    {/*                        Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id*/}
                    {/*                        interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus*/}
                    {/*                        scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper*/}
                    {/*                        dignissim.*/}
                    {/*                        Mauris ultrices eros in cursus turpis massa tincidunt dui.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <a data-bs-toggle="collapse" data-bs-target="#faq5" className="collapsed">Tempus*/}
                    {/*                    quam*/}
                    {/*                    pellentesque nec nam aliquam sem et tortor consequat? <i*/}
                    {/*                        className="bx bx-chevron-down icon-show"></i><i*/}
                    {/*                        className="bx bx-x icon-close"></i></a>*/}
                    {/*                <div id="faq5" className="collapse" data-bs-parent=".faq-list">*/}
                    {/*                    <p>*/}
                    {/*                        Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim*/}
                    {/*                        suspendisse in est ante in. Nunc vel risus commodo viverra maecenas*/}
                    {/*                        accumsan.*/}
                    {/*                        Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit*/}
                    {/*                        turpis cursus in*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <a data-bs-toggle="collapse" data-bs-target="#faq6" className="collapsed">Tortor*/}
                    {/*                    vitae purus*/}
                    {/*                    faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i*/}
                    {/*                        className="bx bx-chevron-down icon-show"></i><i*/}
                    {/*                        className="bx bx-x icon-close"></i></a>*/}
                    {/*                <div id="faq6" className="collapse" data-bs-parent=".faq-list">*/}
                    {/*                    <p>*/}
                    {/*                        Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae*/}
                    {/*                        ultricies*/}
                    {/*                        leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet.*/}
                    {/*                        Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu*/}
                    {/*                        scelerisque. Pellentesque diam volutpat commodo sed egestas egestas*/}
                    {/*                        fringilla*/}
                    {/*                        phasellus faucibus. Nibh tellus molestie nunc non blandit massa enim nec.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*        </ul>*/}

                    {/*    </div>*/}
                    {/*</section>*/}


                    <section id="team" className="team section-bg">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>Team</h2>
                                <p>Here is the company's team.</p>
                            </div>

                            <div className="row">

                                <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                    <div className="member" data-aos="fade-up" data-aos-delay="100">
                                        <div className="member-img">
                                            <img src="client/img/team/team1.jpg" className="img-fluid" alt=""/>
                                            <div className="social">
                                                <a href=""><i className="bi bi-twitter"></i></a>
                                                <a href=""><i className="bi bi-facebook"></i></a>
                                                <a href=""><i className="bi bi-instagram"></i></a>
                                                <a href=""><i className="bi bi-linkedin"></i></a>
                                            </div>
                                        </div>
                                        <div className="member-info">
                                            <h4>Brandon Njimefo</h4>
                                            <span>Chief Executive Officer</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                    <div className="member" data-aos="fade-up" data-aos-delay="200">
                                        <div className="member-img">
                                            <img src="client/img/team/team2.jpg" className="img-fluid" alt=""/>
                                            <div className="social">
                                                <a href=""><i className="bi bi-twitter"></i></a>
                                                <a href=""><i className="bi bi-facebook"></i></a>
                                                <a href=""><i className="bi bi-instagram"></i></a>
                                                <a href=""><i className="bi bi-linkedin"></i></a>
                                            </div>
                                        </div>
                                        <div className="member-info">
                                            <h4>Aymar Le Père Tchimwa B.</h4>
                                            <span>Senior FullStack Developer</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                                    <div className="member" data-aos="fade-up" data-aos-delay="300">
                                        <div className="member-img">
                                            <img src="client/img/team/team3.jpg" className="img-fluid" alt=""/>
                                            <div className="social">
                                                <a href=""><i className="bi bi-twitter"></i></a>
                                                <a href=""><i className="bi bi-facebook"></i></a>
                                                <a href=""><i className="bi bi-instagram"></i></a>
                                                <a href=""><i className="bi bi-linkedin"></i></a>
                                            </div>
                                        </div>
                                        <div className="member-info">
                                            <h4>Yannick Koagne Nzali</h4>
                                            <span>Senior FullStack Developer</span>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className="col-lg-3 col-md-6 d-flex align-items-stretch">*/}
                                {/*    <div className="member" data-aos="fade-up" data-aos-delay="400">*/}
                                {/*        <div className="member-img">*/}
                                {/*            <img src="client/img/team/team-4.jpg" className="img-fluid" alt=""/>*/}
                                {/*            <div className="social">*/}
                                {/*                <a href=""><i className="bi bi-twitter"></i></a>*/}
                                {/*                <a href=""><i className="bi bi-facebook"></i></a>*/}
                                {/*                <a href=""><i className="bi bi-instagram"></i></a>*/}
                                {/*                <a href=""><i className="bi bi-linkedin"></i></a>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="member-info">*/}
                                {/*            <h4>Amanda Jepson</h4>*/}
                                {/*            <span>Accountant</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                            </div>

                        </div>
                    </section>

                    <section id="contact" className="contact">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>Contact</h2>
                                <p>Directly contact the team by sending us a message.</p>
                            </div>

                            <div className="row" data-aos="fade-up" data-aos-delay="100">

                                <div className="col-lg-6">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="info-box">
                                                <i className="bx bx-map"></i>
                                                <h3>Our Address</h3>
                                                <p>A108 Adam Street, Cameroon, NY 535022</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="info-box mt-4">
                                                <i className="bx bx-envelope"></i>
                                                <h3>Email Us</h3>
                                                <p>NTB@company.com</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="info-box mt-4">
                                                <i className="bx bx-phone-call"></i>
                                                <h3>Call Us</h3>
                                                <p>+1 5589 55488 55</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6">
                                    <form action="forms/contact.php" method="post" role="form"
                                          className="php-email-form">
                                        <div className="row">
                                            <div className="col form-group">
                                                <input type="text" name="name" className="form-control" id="name"
                                                       placeholder="Your Name" required/>
                                            </div>
                                            <div className="col form-group">
                                                <input type="email" className="form-control" name="email" id="email"
                                                       placeholder="Your Email" required/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="subject" id="subject"
                                                   placeholder="Subject" required/>
                                        </div>
                                        <div className="form-group">
                                        <textarea className="form-control" name="message" rows="5" placeholder="Message"
                                                  required></textarea>
                                        </div>
                                        <div className="my-3">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit">Send Message</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </section>

                </main>

                <GlobalFooter />
                <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i
                    className="bi bi-arrow-up-short"></i></a>

            </div>
        </>
    );
}

export default HomeDefault;