import React from "react";
import GlobalHeader from "../../components/GlobalHeader";
import GlobalFooter from "../../components/GlobalFooter";

function ServiceDetails() {

    return (
        <>
          <GlobalHeader />

            <main id="main" style={{marginTop: '10%'}}>

                <section className="breadcrumbs">
                    <div className="container">

                        <ol>
                            <li><a href="index.html">Home</a></li>
                            <li>Service details</li>
                        </ol>
                        <h2>Service details Page</h2>

                    </div>
                </section>

                <section className="inner-page">
                    <div className="container" data-aos="fade-up">
                        <p>
                            Example inner page template
                        </p>
                    </div>
                </section>

            </main>
            <GlobalFooter/>
        </>
    );
}

export default ServiceDetails;