import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import routes from "./routes";
import axios from "axios";
import { AuthContextProvider } from './context/AuthContext';

// set main base url
//axios.defaults.baseURL = "http://localhost:8000/api";
axios.defaults.baseURL = "https://test.api.ntb-entreprise.de/api";

// Request interceptor for API calls
axios.interceptors.request.use(
  async config => {

    let access_token = "";
    if (localStorage.getItem("access_token")) {
      access_token = localStorage.getItem("access_token");
    }

    if (sessionStorage.getItem("access_token")) {
      access_token = sessionStorage.getItem("access_token");
    }

    config.headers = {
      'Authorization': `Bearer ${access_token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
axios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("access_token");
    window.location.replace('/');
  }
  return Promise.reject(error);
});

const App = () => {

  const routeComponents = routes.map(({ path, component }, key) => <Route exact path={path} element={component} key={key} />);

  return (
    <Router>
      <AuthContextProvider>
        <Routes>
          {routeComponents}
        </Routes>
      </AuthContextProvider>
    </Router>
  );

}

export default App;
