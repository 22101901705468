import React from "react";

function Footer() {

    return (
        <div >
            <footer className="site-footer">
                <div className="text-center">
                    <p>
                        &copy; Copyrights <strong>Dashio</strong>. All Rights Reserved
                    </p>
                    <div className="credits">

                        Created with Dashio template by <a href="https://templatemag.com/">TemplateMag</a>
                    </div>
                    <a href="#" className="go-top">
                        <i className="fa fa-angle-up"></i>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;