import React, {useEffect, useState} from "react";
import {getCategories, getPaginatedJobs} from "../services/JobServices";

function GlobalFilter({onFilterPostsDetails, onClearFilter}) {
    const [contracts, setContracts] = useState([]);
    const [keyWord, setKeyWord] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoryIds, setCategoryIds] = useState([]);

    const filter = () => {
        if (keyWord !== '' || contracts.length > 0 || categoryIds.length > 0) {
            onFilterPostsDetails(keyWord, categoryIds, contracts);
        }
    }

    const clear = () => {
        setContracts([]);
        setCategoryIds([]);
        setKeyWord('');
        const form = document.getElementById('update-session-form');
        form.reset();
        onClearFilter();
    }

    const handleChangeCategory = (event) => {
        let categoryId = (event.target.value).toString();

        console.log('la valeur disponible est ' + categoryId);
        console.log(categoryIds)

        if (!categoryIds.includes(categoryId)) {
            console.log("la valeur entrée est " + categoryId)
            setCategoryIds(categoryIds => [...categoryIds, categoryId]);

            console.log('le nouveau tableau est ')
            console.log(categoryIds)
        } else {
            console.log("la valeur entrée est " + categoryId)
            let tabCategory = categoryIds.filter(e => e === categoryId);
            setCategoryIds(tabCategory);

            console.log('le nouveau tableau est ')
            console.log(categoryIds)
        }

        //setCategory(event.target.value);
    };

    const handleChangeKeyWord = (event) => {
        setKeyWord(event.target.value);
    };

    const handleChangeContract = (event) => {

        let contractType = event.target.value;
        console.log(contractType);

        if (!contracts.includes(contractType)) {
            console.log("il ne contient pas " + contractType)
            setContracts(contracts => [...contracts, contractType]);

            console.log('le nouveau tableau est ')
            console.log(categoryIds)
        } else {
            console.log("il contient " + contractType)
            let tabContracts = contracts.filter(e => e !== contractType);
            setContracts(tabContracts);

            console.log('le nouveau tableau est ')
            console.log(contracts)
        }
        // setContract(event.target.value);
    };

    useEffect(() => {
        const retrieveJobs = async () => {
            let categories = await getCategories();
            console.log("voici les categories");
            console.log(categories);
            // const tabCategories = categories.filter(e => e.name !== 'Default');
            //setCategories(tabCategories);
            setCategories(categories);
        }
        retrieveJobs();

    }, []);


    return (
        <div>
            <form id="update-session-form">
                <div className="portfolio-info">
                    <h3>Filter</h3>
                    <div className="form-group">
                        <input type="text" className="form-control"
                               placeholder="Key word" name="keyWord" value={keyWord} onChange={handleChangeKeyWord}/>
                    </div>
                    <hr/>
                    <h5><strong>Categories</strong></h5>
                    <ul>
                        {
                            categories?.map((item) =>
                                <>
                                    {item.category !== null ?
                                        <li><input type="checkbox" value={item.id} onClick={handleChangeCategory}/>
                                            &nbsp;&nbsp; <strong>{item.name}</strong></li>
                                        : ''
                                    }
                                </>
                            )
                        }

                    </ul>
                    <hr/>
                    <h5><strong>Contrat Type</strong></h5>
                    <ul>
                        <li><input type="checkbox" value="CDD" onClick={handleChangeContract}/>
                            &nbsp;&nbsp; <strong>CDD</strong></li>
                        <li><input type="checkbox" value="CDI" onClick={handleChangeContract}/>
                            &nbsp;&nbsp; <strong>CDI</strong></li>
                        <li><input type="checkbox" value="TEMPORARY" onClick={handleChangeContract}/>
                            &nbsp;&nbsp; <strong>TEMPORARY</strong></li>
                        <li><input type="checkbox" value="PERMANENT" onClick={handleChangeContract}/>
                            &nbsp;&nbsp; <strong>PERMANENT</strong></li>
                    </ul>
                </div>
                <div className="sidebar mt-3" style={{textAlign: 'center'}}>
                    <div className="sidebar-item categories">
                        <button className="btn btn-theme btn-block me-3"
                                type="button" onClick={filter}>Search
                        </button>
                        <button className="btn btn-danger btn-block"
                                type="button" onClick={clear}>
                            <b>Reset</b>
                        </button>
                    </div>
                </div>
            </form>
        </div>

    );
}

export default GlobalFilter;
