import axios from "axios"

export const connection = (data) => {
    let loginFormData = new FormData();

    loginFormData.append("email", data.email);
    loginFormData.append("password", data.password);
    loginFormData.append("remember", data.remember);

    console.log(data);
    console.log("on essaie la connexion");

    return new Promise((resolve, reject) => {
        axios
            .post('/login', loginFormData)
            .then(response => {
                if (response.data.data.remember) {
                    localStorage.setItem("access_token", response.data.data.token);
                    localStorage.setItem("user_id", response.data.data.user_id);
                } else {
                    sessionStorage.setItem("access_token", response.data.data.token);
                    sessionStorage.setItem("user_id", response.data.data.user_id);
                }

                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    });
};

export const registration = (data) => {

    console.log(data);

    let registerFormData = new FormData();

    registerFormData.append("username", data.username);
    registerFormData.append("phone", data.phone);
    registerFormData.append("first_name", data.first_name);
    registerFormData.append("last_name", data.last_name);
    registerFormData.append("email", data.email);
    registerFormData.append("address", data.address);
    registerFormData.append("password", data.password);
    registerFormData.append("password_confirmation", data.password_confirmation);
    registerFormData.append("question", null);
    registerFormData.append("answer", null);

    return new Promise((resolve, reject) => {
        axios
            .post('/register', registerFormData)
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const resent = (email) => {
    let emailFormData = new FormData();

    emailFormData.append("email", email);

    console.log(email);

    return new Promise((resolve, reject) => {
        axios
            .post('/email/verify/resend', emailFormData)
            .then(response => {

                resolve(response.data);

            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const updateUser = (data, id) => {
    let toSend = {
        "username": data.username,
        "phone": data.phone,
        "first_name": data.first_name,
        "last_name": data.last_name,
        "email": data.email,
        "address": data.address
    }

    return new Promise((resolve, reject) => {
        axios
            .put(`/update/${id}`, toSend)
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};