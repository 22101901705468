import axios from "axios";

export function getAllJobs() {
    return new Promise((resolve, reject) => {
        axios
            .get('/jobs')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getOne(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/job/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const createJob = (data, type, payment, user_id) => {

    return new Promise((resolve, reject) => {
        axios
            .post(`/create/job/user/${user_id}`, data)
            .then(response => {
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const applyJob = (job_id, user_id, cv, motivation, idCard, attachements, aboutUser, updateProgress) => {

    let jobFormData = new FormData();

    for (const file of attachements) {
        jobFormData.append("attachements[]", file.file);
    }

    for (const file of attachements) {
        jobFormData.append("attachements_name[]", file.fileName);
    }

    jobFormData.append("cv", cv);
    jobFormData.append("motivation_letter", motivation);
    jobFormData.append("id_card", idCard);
    jobFormData.append("about_user", aboutUser);

    const options = {
        onUploadProgress: (ProgressEvent) => {
            const {loaded, total} = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent < 100) {
                updateProgress(percent);
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`/apply/job/${job_id}/user/${user_id}`, jobFormData, options, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                updateProgress(100);
                console.log(response.data.data);
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export const responseRequest = (id, file) => {

    let formData = new FormData();

    formData.append("file", file);

    return new Promise((resolve, reject) => {
        axios
            .post(`/job-user-request/${id}/response`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
};

export function verifyApplication(job_id, user_id) {

    return new Promise((resolve, reject) => {
        axios
            .get(`/verify/job/${job_id}/user/${user_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getApplicationByJobAndUser(job_id, user_id) {
  
    return new Promise((resolve, reject) => {
        axios
            .get(`/application/job/${job_id}/user/${user_id}`)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getJobUser(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/application/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getUserApplications(id) {
    return new Promise((resolve, reject) => {
        axios
            .post(`/applications/user/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getApplicationRequests(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/requests/job-user/${id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedJobs(page, nb_of_element) {
    if (page !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                // eslint-disable-next-line no-useless-concat
                .post('/paginated/jobs?page=' + `${page}`)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        let data = {'numberElement': nb_of_element};
        return new Promise((resolve, reject) => {
            axios
                .post('/paginated/jobs', data)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
};

export function getPaginatedJobsWithRange(nb_of_element) {
    let data = {'numberElement': nb_of_element};

    return new Promise((resolve, reject) => {
        axios
            .post('/paginate/jobs/range', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function categoryIdsfilterPostsDetails(keyWord, categoryIds, contracts) {

    const data = {
        key_word: keyWord,
        categories: categoryIds,
        contracts: contracts,
    }

  console.log(data)
    return new Promise((resolve, reject) => {
        axios
            .post('/filter/jobs/details', data)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}


export function getCategories() {

    return new Promise((resolve, reject) => {
        axios
            .get(`/categories`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getCategoriesCount() {

    return new Promise((resolve, reject) => {
        axios
            .get(`/categories/count`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getRecentJobs() {

    return new Promise((resolve, reject) => {
        axios
            .get(`/recent/jobs`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedJobUsers(id, page, nb_of_element) {
    if (page !== undefined) {
        return new Promise((resolve, reject) => {
            axios
                // eslint-disable-next-line no-useless-concat
                .post(`/applications/user/${id}?page=${page}`)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    } else {
        let data = {'numberElement': nb_of_element};
        return new Promise((resolve, reject) => {
            axios
                .post(`/applications/user/${id}`, data)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
};
export function getPaginatedJobsUsersWithRange(id, nb_of_element) {
    let data = {'numberElement': nb_of_element};

    return new Promise((resolve, reject) => {
        axios
            .post(`/applications/user/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

