import React, { useState } from "react";
import { useForm } from "react-hook-form";
import GlobalHeader from "../components/GlobalHeader";
import { forgot, resetP } from "../services/UserServices";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import GlobalFooter from "../components/GlobalFooter";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ResetPassword() {
  const {register, handleSubmit, reset, formState: { errors }} = useForm();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();
  //const connected_user = JSON.parse(localStorage.getItem('connected_user'));

  const resetPassword = async (data) => {
    
    console.log(data);
    try {
      await resetP(data);

      setAlertMessage("Password successfully reset !");
      setAlertType("success");
      handleOpenAlert();
      reset();
        navigate('/login');

    } catch (error) {
      console.log(error.message);
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
          <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <GlobalHeader />
      <main id="main">
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">


            <div className="section-title mt-5">
              <h2>Reset Your Password</h2>
              <p>Make sure you have the validation token from the email you received.</p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">

                <img src="client/img/reset.png" className="img-fluid"
                  alt="" />

              </div>

              <div className="col-lg-6 mt-5">
                <form onSubmit={handleSubmit(resetPassword)} className="php-email-form">
                  <div className="form-group">
                    <input type="email" className="form-control" {...register("email")} autoFocus
                      placeholder="Your Email" required />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control"
                      placeholder="New Password"  {...register("password", {
                        required: "New Password",
                      })} required />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control"
                      placeholder="Confirm New Password" {...register("password_confirmation", {
                        required: "Confirm new password",
                      })} required />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" {...register("token")}
                      placeholder="Validation Token" required />
                  </div>

                  <div className="text-center">
                    <button type="submit">Change Password</button>
                  </div>
                </form>

                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                  Already have an account ?<br />
                  <a className="" href="/login">
                    <span style={{ color: '#f57b06' }}>Sign in here</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <GlobalFooter />
    </>
  );
}

export default ResetPassword;