import axios from "axios";
  
export const me = () => {

  return new Promise((resolve, reject) => {
    axios
      .get('/me')
      .then( response => {

        if(response.data){
console.log(response.data.data);
        resolve(response);
        }else {
          throw new Error('USER NOT FOUND');
        }
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
};

export const forgot = (data) => {
  let emailFormData = new FormData();

  emailFormData.append("email", data.email);

  return new Promise((resolve, reject) => {
    axios
      .post('/forgetpassword', emailFormData)
      .then( response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response.data);
      });
    });
};

export const resetP = (data) => {

  let resetFormData = new FormData();

  resetFormData.append("email", data.email);
  resetFormData.append("password", data.password);
  resetFormData.append("password_confirmation", data.password_confirmation);
  resetFormData.append("token", data.token);

  return new Promise((resolve, reject) => {
    axios
      .post('/resetpassword', resetFormData)
      .then( response => {
        console.log(response.data);

        resolve(response);
      })
      .catch(error => {
        reject(error.response.data);
      });
    });
  };

export function getUser(id) {
  return new Promise((resolve, reject) => {
      axios
          .get(`/user/${id}`)
          .then(response => {
              resolve(response.data.data);
          })
          .catch(error => {
              reject(error.response.data);
          });
  });
}

export function updateAvatar(id, image) {
    let formData = new FormData();
    formData.append("avatar", image);

    return new Promise((resolve, reject) => {
        axios
            .post('/user/'+`${id}`+'/avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function removeAvatarService(id, image) {

    return new Promise((resolve, reject) => {
        axios
            .put('/user/'+`${id}`+'/avatar/remove', image)
            .then( response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};

export function updateInfos(id, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/update/${id}`, data)
            .then( response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

};