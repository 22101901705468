import React, { useEffect, useState } from "react";
import GlobalHeader from "../../components/GlobalHeader";
import { categoryIdsfilterPostsDetails, getPaginatedJobs, getPaginatedJobsWithRange } from "../../services/JobServices";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import GlobalFilter from "../../components/GlobalFilter";
import { FormGroup } from "@mui/material";
import GlobalFooter from "../../components/GlobalFooter";

function Job() {
    const [jobs, setJobs] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [nbElement, setNbElement] = useState('');

    const handlePageClick = async (data) => {
        const requestedPage = data.selected + 1;
        console.log(requestedPage)
        let posts = await getPaginatedJobs(requestedPage, nbElement);
        console.log("les posts");
        console.log(posts);
        setJobs(posts.data);
        setLastPage(posts.last_page);
    }

    const handleSearch = async (e) => {
        let nb = e.target.value;
        let posts = await getPaginatedJobsWithRange(parseInt(nb));
        console.log(posts)
        setJobs(posts.data);
        setLastPage(posts.last_page);
    }

    const handleFilterDetails = async (keyWord, categoryIds, contracts) => {
        try {
            let jobs = await categoryIdsfilterPostsDetails(keyWord, categoryIds, contracts);
            console.log(jobs);
            setJobs(jobs.result);
        } catch (error) {
            console.log(error.data.message);
        }
    };

    const retrieveJobs = async () => {
        let jobs = await getPaginatedJobs();
        setJobs(jobs.data);
        setLastPage(jobs.meta.last_page);
    }


    useEffect(() => {
        const retrieveJobs = async () => {
            let jobs = await getPaginatedJobs();
            console.log("voici les jobs");
            console.log(jobs);
            // let arr = Object.values(data)
            // console.log(arr);
            setJobs(jobs.data);
            setLastPage(jobs.meta.last_page);

        }
        retrieveJobs();

    }, []);

    return (
        <>
            <div style={{ backgroundColor: "#fff" }}>
                <GlobalHeader />
                <main id="main">

                    <section className="breadcrumbs">
                        <div className="container">
                            <h2>All Jobs</h2>
                            <ol>
                                <li>Use form to filter with key elements</li>
                            </ol>
                        </div>
                    </section>
                    <section id="portfolio-details" className="portfolio-details">
                        <div className="container">

                            <div className="row gy-4">
                                <div className="col-lg-3">

                                    <GlobalFilter onFilterPostsDetails={handleFilterDetails} onClearFilter={retrieveJobs} />

                                </div>
                                <div className="col-lg-9">
                                    <div className="portfolio-details-slider swiper">
                                        <div id="unseen">
                                            <table className="table table-bordered table-striped table-condensed">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Job name</th>
                                                        <th>Description</th>
                                                        <th>Categories</th>
                                                        <th>Languages</th>
                                                        <th>Type</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {
                                                        jobs.length >= 1 ? jobs?.map((item) =>
                                                            <tr key={item.id}>
                                                                <td>{item.id}</td>
                                                                <td style={{ width: '15%' }}>
                                                                    <Link to={{
                                                                        pathname: "/job-details",
                                                                        search: '?jobId=' + item.id
                                                                    }}>{item.title}</Link></td>
                                                                <td style={{ width: '50%' }}>{item.description}</td>
                                                                <td><label style={{ color: '#4a81b7' }}>{(item.categories).length > 0 ? (item.categories).map((item) => <div> {item.name} </div>): 'Default'}</label></td>
                                                                <td>{item.language}</td>
                                                                <td>{item.type}</td>
                                                            </tr>
                                                        ) : <h4>No result found</h4>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                    <div className="row mt">

                                        <div className="col-md-4">
                                            <form>
                                                <FormGroup className="mb-0" style={{ width: "100px" }}>
                                                    <select className="form-control" onChange={(e) => {
                                                        // console.log(e.target.value);
                                                        handleSearch(e)
                                                    }}>
                                                        <option value="5">5</option>
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="20">20</option>
                                                        <option value="25" selected>25</option>
                                                    </select>
                                                </FormGroup>
                                            </form>
                                        </div>
                                        <div className="col-md-6"></div>
                                        <div className="col-md-2">
                                            <nav aria-label="...">
                                                <ReactPaginate
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    breakLabel={'...'}
                                                    pageCount={lastPage}
                                                    marginPagesDisplayed={3}
                                                    pageRangeDisplayed={6}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination justify-content-end mb-0"}
                                                    pageClassName={"page-item"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    pageLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}

                                                />
                                            </nav>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>
                </main>

                <GlobalFooter />

                <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i
                    className="bi bi-arrow-up-short"></i></a>

            </div>

        </>
    );
}

export default Job;