import React, { useContext, useEffect, useRef, useState } from "react";
import GlobalHeader from "../../components/GlobalHeader";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalSidebar from "../../components/GlobalSidebar";
import { applyJob, getOne, verifyApplication } from "../../services/JobServices";
import GlobalFilter from "../../components/GlobalFilter";
import { v4 as uuidv4 } from "uuid";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AuthContext } from "../../context/AuthContext";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function JobDetails() {
    const navigate = useNavigate();
    const { connected, user } = useContext(AuthContext);
    const [job, setJob] = useState('');
    const [tasks, setTasks] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [apply, setApply] = useState(false)
    const cvInputRef = useRef(null);
    const motivInputRef = useRef(null);
    const idInputRef = useRef(null);
    const attachInputRef = useRef(null);
    const [cv, setCv] = useState(null);
    const [cvName, setCvName] = useState("");
    const [motivation, setMotivation] = useState(null);
    const [motivName, setMotivName] = useState("");
    const [idCard, setIdCard] = useState(null);
    const [idName, setIdName] = useState("");
    const [attachements, setAttachements] = useState([]);
    const [fileName, setFileName] = useState("");
    const [aboutUser, setAboutUser] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [isFileNameValid, setIsFileNameValid] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [progress, setProgress] = useState(0);
    const [hasApply, setHasApply] = useState(null);
    const fileInputRef = useRef(null);

    const updateProgress = (val) => {
        setProgress(val);
    };

    const handleUploadCv = (event) => {
        var file = event.target.files[0];
        var fSize = Math.round((file.size / 1024));
        if (fSize > 5120) {
            setAlertMessage("file size should be less than 5Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileName = file.name;
            setCvName(fileName);
            setCv(file);
        }
        event.target.value = null;
    }

    const handleUploadMotiv = (event) => {
        var file = event.target.files[0];
        var fSize = Math.round((file.size / 1024));
        if (fSize > 5120) {
            setAlertMessage("file size should be less than 5Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileName = file.name;
            setMotivName(fileName);
            setMotivation(file);
        }
        event.target.value = null;
    }

    const handleUploadId = (event) => {
        var file = event.target.files[0];
        var fSize = Math.round((file.size / 1024));
        if (fSize > 5120) {
            setAlertMessage("file size should be less than 5Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileName = file.name;
            setIdName(fileName);
            setIdCard(file);
        }
        event.target.value = null;
    }

    const handleUploadAttach = (event) => {
        var file = event.target.files[0];
        var fSize = Math.round((file.size / 1024));
        if (fSize > 5120) {
            setAlertMessage("file size should be less than 5Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileID = uuidv4() + "." + file.name.split('.').pop();
            let fName = file.name;
            setAttachements([...attachements, { key: fileID, name: fName, fileName: fileName, file: file }]);
        }
        setFileName("");
        setIsFileNameValid(false);
        event.target.value = null;
    }

    const handleRemoveAttach = (attachKey) => {
        const myAttachs = attachements.filter(element => element.key !== attachKey);
        setAttachements(myAttachs);
    }

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleClickCvInput = () => {
        cvInputRef.current.click();
    }
    const handleClickMotivInput = () => {
        motivInputRef.current.click();
    }
    const handleClickIdInput = () => {
        idInputRef.current.click();
    }
    const handleClickAttachInput = () => {
        attachInputRef.current.click();
    }

    const saveApplication = async () => {

        let job_id = job.id;
        let user_id = user?.id;

        try {
            let jobUser = await applyJob(job_id, user_id, cv, motivation, idCard, attachements, aboutUser, updateProgress);
            setHasApply(jobUser);

            setAlertMessage("Your application sucessfully saved !");
            setAlertType("success");
            handleOpenAlert();

            setApply(false);
            setCv(null);
            setCvName("");
            setIdCard(null);
            setIdName("");
            setMotivation(null);
            setMotivName("");
            setAttachements([]);

        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();

            setCv(null);
            setCvName("");
            setIdCard(null);
            setIdName("");
            setMotivation(null);
            setMotivName("");
            setAttachements([]);
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('jobId');

        const retrieveJob = async () => {
            let data = await getOne(id);
            console.log("voici le job");
            console.log(data);
            setJob(data);
            setTasks(data.tasks);
            setQualifications(data.qualifications);
        }

        const verifyApp = async () => {
            var user_id = user?.id;
            let jobUser = await verifyApplication(id, user_id);
            setHasApply(jobUser);
            console.log(hasApply);
        }

        if (user) {
            verifyApp();
        }
        retrieveJob();

    }, [user]);

    const handleChangeFileName = (event) => {
        var val = event.target.value;
        setFileName(val);
            if (val.length >= 5) {
                setIsFileNameValid(true);
            }else{
                setIsFileNameValid(false);
            }
    }

    const handleChangeAboutUser = (event) => {
        var val = event.target.value;
        setAboutUser(val);
    }

    const viewApplication = () => {

        let job_id = job.id;
        let user_id = user.id;

        navigate('/application-details',
        { state: { jobId: job_id, userId: user_id } });
    }

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={6000}
                onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <GlobalHeader/>
            <main id="main">
                <div className="breadcrumbs">
                    <div className="container">
                        <h2>Job details</h2>
                        <ol>
                            <li>Please send your application</li>
                        </ol>
                    </div>
                </div>

                <div id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-8 entries">
                                <article className="entry">
                                    <div className="entry-img">
                                        <img src="client/img/blog/blog-1.jpg" alt="" className="img-fluid" />
                                    </div>
                                    <h2 className="entry-title">
                                        {job.title}
                                    </h2>
                                    <div className="entry-meta">
                                        <ul>
                                            <li className="d-flex align-items-center"><i
                                                className="bi bi-person"></i> <a href="blog-single.html">John
                                                    Doe</a></li>
                                            <li className="d-flex align-items-center"><i
                                                className="bi bi-clock"></i> <a href="blog-single.html">
                                                    <time dateTime="2020-01-01">{job.date}</time>
                                                </a></li>
                                            <li className="d-flex align-items-center"><i
                                                className="bi bi-pen-fill"></i> <a href="blog-single.html">
                                                    <time dateTime="2020-01-01">{job.type}</time>
                                                </a></li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <div className="entry-content">
                                        <h4><b>Description</b></h4>
                                        <p>
                                            {job.description}
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="entry-content">
                                        <h4><b>Qualifications</b></h4>
                                        <ul>
                                            {
                                                tasks?.map((item) =>
                                                    <li>{item.name}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    <hr />
                                    <div className="entry-content">
                                        <h4><b>Tasks</b></h4>
                                        <div>
                                            <ul>
                                                {
                                                    qualifications?.map((item) =>
                                                        <li>{item.name}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="read-more">
                                            {hasApply == null ?
                                                <a
                                                    href="javascript:document.getElementById('application-form').scrollIntoView(true);"
                                                    className="btn btn-primary"
                                                    onClick={(e) => setApply(true)}
                                                >Apply now</a> : <> You have already applied <button className="btn btn-primary" onClick={() => viewApplication()}>See Application</button></>}
                                        </div>
                                    </div>
                                </article>
                            </div>

                            <div className="col-lg-4">
                                <GlobalSidebar />
                            </div>

                            {apply ?
                                <>
                                    <div className="row">
                                        <div className="col-5 body-center"></div>
                                        <div className="col-6 body-center">
                                            <h2 className="form-login-heading" id="application-form">Apply here</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <form className="form-login_application">
                                                <div className="login-wrap">
                                                    <input type="text" defaultValue={user?.username} className="form-control" />

                                                    <input type="text" defaultValue={user?.last_name} className="form-control"
                                                        name="last_name" style={{ marginTop: '10px' }}
                                                    />
                                                    <input type="text" className="form-control" style={{ marginTop: '10px' }}
                                                        defaultValue={user?.first_name}
                                                        name="first_name"
                                                    />
                                                    <textarea name="about_user" className="form-control mt" value={aboutUser} onChange={handleChangeAboutUser}cols="30" rows="5" placeholder="Abour user"></textarea>
                                                    <div className="row" style={{ padding: '7px' }}>
                                                        <div className="col-2">
                                                            <input
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={cvInputRef}
                                                                onChange={handleUploadCv} />
                                                            <button style={{ backgroundColor: '#337ab7', border: '#337ab7' }} className="btn btn-theme btn-block form-control"
                                                                type="button" onClick={handleClickCvInput}>{cvName === "" ? "Upload CV" : "Change CV"}
                                                            </button>
                                                        </div>
                                                        <div className="col-10">
                                                            <span  className="form-control">{cvName === "" ? "No file chosen" : cvName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ padding: '7px' }}>
                                                        <div className="col-2">
                                                            <input
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={motivInputRef}
                                                                onChange={handleUploadMotiv} />
                                                            <button style={{ backgroundColor: '#337ab7', border: '#337ab7' }} className="btn btn-theme btn-block form-control"
                                                                type="button" onClick={handleClickMotivInput}>{motivName === "" ? "Motivation" : "Change Motivation"}
                                                            </button>
                                                        </div>
                                                        <div className="col-10">
                                                            <span  className="form-control">{motivName === "" ? "No file chosen" : motivName}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ padding: '7px' }}>
                                                        <div className="col-2">
                                                            <input
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={idInputRef}
                                                                onChange={handleUploadId} />
                                                            <button style={{ backgroundColor: '#337ab7', border: '#337ab7' }} className="btn btn-theme btn-block form-control"
                                                                type="button" onClick={handleClickIdInput}>{idName === "" ? "ID Card" : "Change ID"}
                                                            </button>
                                                        </div>
                                                        <div className="col-10">
                                                            <span className="form-control">{idName === "" ? "No file chosen" : idName}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        attachements?.map((item) =>
                                                            <div key={item.key} className="row" style={{ padding: '7px' }}>
                                                                <div className="col-2">
                                                                    <button style={{ backgroundColor: 'red', border: 'red' }} className="btn btn-theme btn-block form-control"
                                                                        type="button" onClick={() => handleRemoveAttach(item.key)}>Remove
                                                                    </button>
                                                                </div>
                                                                <div className="col-10">
                                                                    <span className="form-control">{item.fileName + " : " + item.name}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="row" style={{ padding: '7px' }}>
                                                        <div className="col-2">
                                                            <input
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={attachInputRef}
                                                                onChange={handleUploadAttach} />
                                                            {isFileNameValid ?
                                                                <button style={{ backgroundColor: '#337ab7', border: '#337ab7' }} className="btn btn-theme btn-block form-control"
                                                                    type="button" onClick={handleClickAttachInput}>Other File
                                                                </button>
                                                                :
                                                                <button style={{ backgroundColor: '#337ab7', border: '#337ab7' }} className="btn btn-theme btn-block form-control"
                                                                    type="button" onClick={handleClickAttachInput} disabled>Other File
                                                                </button>
                                                            }

                                                        </div>
                                                        <div className="col-10">
                                                            <input type="text" ref={fileInputRef} value={fileName} onChange={handleChangeFileName} style={{ padding: '1px', paddingLeft: '12px' }} className="form-control" placeholder="Please enter file description before upload" />
                                                            {isFileNameValid ? ""
                                                                :
                                                                <span className="help-block" style={{ color: 'red' }}>More that 5 caracter required !</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {progress > 0 && progress < 100 ?
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box> : ''
                                    }
                                    <div className="row">
                                        <div className="col-2 ">
                                        </div>
                                        <div className="col-5 ">
                                            <div>
                                                <button className="btn btn-theme btn-block"
                                                    type="button" onClick={saveApplication}><i className="fa fa-paper-plane-o"></i> SEND
                                                    APPLICATION
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-2 "></div>
                                    </div>
                                </>
                                : null}


                        </div>

                    </div>
                </div>

            </main>
            <GlobalFooter />
        </>
    );
}

export default JobDetails;