import React, {useEffect, useState} from "react";
import {getCategories, getRecentJobs} from "../services/JobServices";
import {Link} from "react-router-dom";

function GlobalSidebar() {

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [recentJobs, setRecentJobs] = useState([]);

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    useEffect(() => {
        const retrieveJobs = async () => {
            let categories = await getCategories();
            let recentJobs = await getRecentJobs();
            console.log("voici les categories");
            console.log(categories);
            console.log("voici les jobs recents");
            console.log(recentJobs);
            setRecentJobs(recentJobs);
        }
        retrieveJobs();

    }, []);
    return (
        <div>
            <div className="sidebar">

                {/*<h3 className="sidebar-title">Search</h3>*/}
                {/*<div className="sidebar-item search-form">*/}
                {/*    <form action="">*/}
                {/*        <input type="text" />*/}
                {/*        <button type="submit"><i className="bi bi-search"></i></button>*/}
                {/*    </form>*/}
                {/*</div>*/}

                {/*<h3 className="sidebar-title">Categories</h3>*/}
                {/*<div className="sidebar-item categories">*/}
                {/*    {*/}
                {/*        categories?.map((item) =>*/}
                {/*            <>*/}
                {/*                {item !== null ?*/}
                {/*                    <>*/}
                {/*                        <li><a href="#">{item.name}*/}
                {/*                            /!*<span>(25)</span>*!/*/}
                {/*                        </a></li>*/}
                {/*                    </> : null*/}
                {/*                }*/}
                {/*            </>*/}
                {/*        )*/}
                {/*    }*/}
                {/*</div>*/}

                <h3 className="sidebar-title">Recent Jobs</h3>
                <div className="sidebar-item recent-posts">
                    {
                        recentJobs?.map((item) =>
                            <>
                                <div className="post-item clearfix">
                                    <img src="client/img/blog/blog-recent-1.jpg" alt=""/>
                                    <h4 style={{cursor : "pointer"}} onClick={() =>
                                        window.location = '/job-details?jobId=' + item.id
                                    }>{item.title}
                                    </h4>
                                    <time dateTime="2020-01-01">{item.created_at}</time>
                                </div>
                            </>
                        )
                    }


                    {/*<div className="post-item clearfix">*/}
                    {/*    <img src="client/img/blog/blog-recent-2.jpg" alt="" />*/}
                    {/*    <h4><a href="#">Quidem autem et impedit</a></h4>*/}
                    {/*    <time dateTime="2020-01-01">Jan 1, 2020</time>*/}
                    {/*</div>*/}

                    {/*<div className="post-item clearfix">*/}
                    {/*    <img src="client/img/blog/blog-recent-3.jpg" alt="" />*/}
                    {/*    <h4><a href="#">Id quia et et ut maxime similique*/}
                    {/*        occaecati ut</a></h4>*/}
                    {/*    <time dateTime="2020-01-01">Jan 1, 2020</time>*/}
                    {/*</div>*/}

                    {/*<div className="post-item clearfix">*/}
                    {/*    <img src="client/img/blog/blog-recent-4.jpg" alt="" />*/}
                    {/*    <h4><a href="#">Laborum corporis quo dara net*/}
                    {/*        para</a></h4>*/}
                    {/*    <time dateTime="2020-01-01">Jan 1, 2020</time>*/}
                    {/*</div>*/}

                </div>

                {/*<h3 className="sidebar-title">Tags</h3>*/}
                {/*<div className="sidebar-item tags">*/}
                {/*    <ul>*/}
                {/*        <li><a href="#">App</a></li>*/}
                {/*        <li><a href="#">IT</a></li>*/}
                {/*        <li><a href="#">Business</a></li>*/}
                {/*        <li><a href="#">Mac</a></li>*/}
                {/*        <li><a href="#">Design</a></li>*/}
                {/*        <li><a href="#">Office</a></li>*/}
                {/*        <li><a href="#">Creative</a></li>*/}
                {/*        <li><a href="#">Studio</a></li>*/}
                {/*        <li><a href="#">Smart</a></li>*/}
                {/*        <li><a href="#">Tips</a></li>*/}
                {/*        <li><a href="#">Marketing</a></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}

            </div>
        </div>
    );
}

export default GlobalSidebar;
