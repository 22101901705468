
import React, { useContext } from "react";
import {MEDIA_URL, MEDIA_URL_AVATAR} from "../constantes";
import { AuthContext } from "../context/AuthContext";

function GlobalHeader() {

    const { connected, user } = useContext(AuthContext);

    // const filter = () => {
    //
    //     onFilterPostsDetails(keyWord, type, time, town, minPrice, maxPrice, postMaxPrice);
    //
    // }


    return (
        <div>
            <header id="header" className="fixed-top d-flex align-items-center">
                <div className="container d-flex align-items-center">
                    <h1 className="logo me-auto"><a href="/"><img src="client/img/logo.jpeg" className="img-fluid"
                        alt="" /></a></h1>

                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li><a className="nav-link scrollto active" href="/">Home</a></li>
                            <li><a className="nav-link scrollto" href="/#about">About</a></li>
                            {/*<li><a className="nav-link scrollto" href="/services">Services</a></li>*/}
                            <li><a href="blog.html">Blog</a></li>
                            <li className="dropdown"><a href="/services"><span>Services</span> <i
                                className="bi bi-chevron-down"></i></a>
                                <ul>
                                    <li><a href="/services-details">Web site</a></li>
                                    <li><a href="/services-details">UI & UX DESIGN</a></li>
                                    <li><a href="/services-details">Application development</a></li>
                                    <li><a href="/services-details">Training and internship</a></li>
                                    <li><a href="/services-details">E-COMMERCE</a></li>
                                    <li><a href="/services-details">SOCIAL MEDIA</a></li>
                                </ul>
                            </li>
                            <li><a className="nav-link scrollto " href="/#products">Products</a></li>
                            <li><a className="nav-link scrollto " href="/jobs">Jobs</a></li>
                            <li><a className="nav-link scrollto" href="/#team">Team</a></li>
                            <li><a className="nav-link scrollto" href="/#contact">Contact</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    {!connected ? (
                            <a href="login" className="get-started-btn scrollto">Login</a>
                    ) : (
                        <a href="/account" className="">
                            <img style={{ height: '35px', marginRight: '5px', marginBottom: '10px', marginLeft: '25px' }} src={MEDIA_URL_AVATAR + user?.avatar} className="img-circle" alt="user"/>
                            {user?.username}
                        </a>
                    )}
                </div>
            </header>
        </div>
    );
}

export default GlobalHeader;
