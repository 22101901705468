import React, { useState } from "react";
import GlobalHeader from "../components/GlobalHeader";
import { resent } from "../services/AuthServices";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalFooter from "../components/GlobalFooter";
import { useForm } from "react-hook-form";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EmailVerified() {

  const location = useLocation();
  const {register, handleSubmit, reset, formState: { errors }} = useForm();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const goToLogin = async () => {
      navigate('/login');
  }

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <GlobalHeader />
      <main id="main">
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">


            <div className="section-title mt-5">
              <h2>Email Sucessfully Verified</h2>
              <p>Please click on the link to go to login page.</p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">

                <img src="client/img/verified.png" className="img-fluid"
                  alt="" />

              </div>

              <div className="col-lg-6 mt-5">
                <form onSubmit={handleSubmit(goToLogin)} className="php-email-form">

                  <div className="text-center">
                    <button type="submit">Navigate to login page</button>
                  </div>
                </form>

                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                  Already have an account ?<br />
                  <a className="" href="/login">
                    <span style={{ color: '#f57b06' }}>Sign in here</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <GlobalFooter />

    </>
  );
}

export default EmailVerified;