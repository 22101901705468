import React, { createContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { me } from '../services/UserServices';

export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [connected, setConnected] = useState(false);

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setConnected(false);
    navigate("/");
  };

  const afterLogin = async () => {
    if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
      try {
        console.log("nous somme dans le after login");
        let result = await me();
        setUser(result.data.data);
        setConnected(true);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    console.log('est ce que la auth context est appelé');
    const fetchUser = async () => {
      if (localStorage.getItem("access_token") || sessionStorage.getItem("access_token")) {
        try {
          let result = await me();
          setUser(result.data.data);
          setConnected(true);
          //navigate("/");
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setUser(null);
        setConnected(false);
        //navigate("/");
        console.log('oui ùais pas pas connecté');
      }
    };

    fetchUser();

  }, []);

  return (
    <AuthContext.Provider value={{ connected, user, logOut, afterLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
