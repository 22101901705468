import React, { useEffect, useRef, useState } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import ReactDOM from 'react-dom';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Aside from "../../components/Aside";

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useLocation } from "react-router-dom";

import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";
import {
    Position,
    Tooltip,
    Viewer,
    Worker,
    PrimaryButton
} from "@react-pdf-viewer/core";

import {
    Row,
    Button,
    CardBody,
    FormGroup,
    Input,
    Col,
} from "reactstrap";

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import {MEDIA_API_URL, MEDIA_URL, MEDIA_URL_AVATAR} from "../../constantes";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { getApplicationByJobAndUser, getApplicationRequests, getJobUser, responseRequest } from "../../services/JobServices";
import { getUser } from "../../services/UserServices";
import Collapsible from "react-collapsible";
// import "@react-pdf-viewer/highlight/lib/styles/index.css";
// import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

const pdfUrl = "http://127.0.0.1:8000/api/media/1661475083.scenario4AG.pdf";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ApplicationDetails() {

    const location = useLocation();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeFile, setActiveFile] = useState("CV");
    const [activeUrl, setActiveUrl] = useState("");
    const [jobUser, setJobUser] = useState(null);
    const [appliedUser, setAppliedUser] = useState(null);
    const [urlToImage, setUrlToImage] = useState("");
    const linkRef = useRef(null);
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [request, setRequest] = useState("");
    const [activeRequest, setActiveRequest] = useState(null);
    const [message, setMessage] = useState("");
    const [notes, setNotes] = useState([]);
    const [requests, setRequests] = useState([]);
    const [openResponseModal, setOpenResponseModal] = useState(false);
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    let noteId = notes.length;

    const [shown, setShown] = useState(false);

    const modalBody = (sampleFileName, fileUrl) => (
        <div
            style={{
                backgroundColor: '#fff',
                flexDirection: 'column',

                /* Fixed position */
                left: 0,
                position: 'fixed',
                top: 0,

                /* Take full size */
                height: '100%',
                width: '100%',

                /* Displayed on top of other elements */
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#000',
                    color: '#fff',
                    display: 'flex',
                    padding: '.5rem',
                }}
            >
                <div style={{ marginRight: 'auto' }}>{sampleFileName}</div>
                <button
                    style={{
                        backgroundColor: '#357edd',
                        border: 'none',
                        borderRadius: '4px',
                        color: '#ffffff',
                        cursor: 'pointer',
                        padding: '8px',
                    }}
                    onClick={() => setShown(false)}
                >
                    Close
                </button>
            </div>
            {fileUrl !== "" ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js">

                <div
                    style={{
                        flexGrow: 1,
                        overflow: 'auto',
                    }}
                >
                    <Viewer
                        initialPage={1}
                        fileUrl={fileUrl}
                    />
                </div>
            </Worker> : ""
            }
        </div>
    );

    const renderHighlightTarget = (props) => {
        const highlightContent = () => {
            const note = {
                id: ++noteId,
                highlightAreas: props.highlightAreas,
                quote: props.selectedText
            };
            setNotes([...notes, note]);
            props.toggle();
        };
        return (
            <div
                style={{
                    background: "#eee",
                    display: "flex",
                    position: "absolute",
                    left: `${props.selectionRegion.left}%`,
                    top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                    transform: "translate(0, 8px)"
                }}
            >
                <Tooltip
                    position={Position.TopCenter}
                    target={
                        <Button onClick={highlightContent}>
                            <MessageIcon />
                        </Button>
                    }
                    content={() => <div style={{ width: "100px" }}>Highlight</div>}
                    offset={{ left: 0, top: -8 }}
                />
            </div>
        );
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    useEffect(() => {
        const job_id = location.state?.jobId;
        const user_id = location.state?.userId;

        const retrieveJobUser = async () => {
            let data = await getApplicationByJobAndUser(job_id, user_id);
            setJobUser(data);

            setUrlToImage(MEDIA_API_URL + data?.cv.path);

            let user = await getUser(data?.user_id);
            setAppliedUser(user);

            let result = await getApplicationRequests(data?.id);
            setRequests(result);
        }

        if (location) {
            retrieveJobUser();
        }

    }, [location]);

    const renderHighlights = (props) => (
        <div>
            {notes.map((note) => (
                <React.Fragment key={note.id}>
                    {note.highlightAreas
                        .filter((area) => area.pageIndex === props.pageIndex)
                        .map((area, idx) => (
                            <div
                                key={idx}
                                style={Object.assign(
                                    {},
                                    {
                                        background: "yellow",
                                        opacity: 0.4
                                    },
                                    props.getCssProperties(area, props.rotation)
                                )}
                            />
                        ))}
                </React.Fragment>
            ))}
        </div>
    );

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        renderHighlights
    });

    const showCurrentFile = (fileType) => {
        setActiveFile(fileType);
        if (fileType === "CV") {
            setUrlToImage(MEDIA_API_URL + jobUser?.cv.path);
        }
        if (fileType === "MOTIVATION") {
            setUrlToImage(MEDIA_API_URL + jobUser?.motivation_letter.path);
        }

        if (fileType === "ID CARD") {
            setUrlToImage(MEDIA_API_URL + jobUser?.id_card.path);
        }

        for (const file of jobUser?.attachements) {

            let purpose = file.purpose;
            console.log(purpose)
            if ([4, "OTHER"].includes(purpose) && file.name === fileType) {

                setUrlToImage(MEDIA_API_URL + file.path);
            }
        }
    };

    const handleResponseRequest = async (id) => {
            await responseRequest(id, file);
    
            setOpenResponseModal(false);
            setAlertMessage("Request succesfully answerd !");
            setOpenAlert(true);

            let data = await getJobUser(jobUser?.id);
            setJobUser(data);

            setUrlToImage(MEDIA_API_URL + data?.cv.path);

            let result = await getApplicationRequests(data?.id);
            setRequests(result);
    };

    const handleRequestModal = () => {
        setOpenRequestModal(!openRequestModal);
    };

    const handleResponseModal = () => {
        setOpenResponseModal(!openResponseModal);
    };

    const handleRejectModal = () => {
        setOpenRejectModal(!openRejectModal);
    };

    const handleChangeRequest = (event) => {
        setRequest(event.target.value);
    };

    const handleClickInputFile = () => {
        fileInputRef.current.click();
    }

    const handleUploadFile = (event) => {
        var file = event.target.files[0];
        var fSize = Math.round((file.size / 1024));
        if (fSize > 5120) {
            setAlertMessage("file size should be less than 5Mo");
            setAlertType("error");
            handleOpenAlert();
        } else {
            let fileName = file.name;
            setFileName(fileName);
            setFile(file);
        }
        handleResponseModal();
        event.target.value = null;
    }

    return (
        <div >
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openResponseModal} onClose={handleResponseModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Response Request</DialogTitle>
                    <DialogContent>
                        <CardBody>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <div className="modal-body">
                                                <p>Here is the file you want to upload : {fileName}</p>
                                                <p>Click on send button to upload it </p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                        </CardBody>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleResponseModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleResponseRequest(activeRequest?.id)}
                            color="primary"
                            type="button"
                        >
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/*<Dialog open={openRejectModal} onClose={handleRejectModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Reject Application</DialogTitle>
                    <DialogContent>
                        <CardBody>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <div className="modal-body">
                                                <p>Are you sure you want to reject this application ?</p>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                        </CardBody>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRejectModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() =>handleChangeApplicationStatus(jobUser?.id, "REJECTED")}
                            color="primary"
                            type="button"
                        >
                            Change
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openRequestModal} onClose={handleRequestModal} aria-labelledby="form-dialog-title">
                <form>
                    <DialogTitle id="form-dialog-title">Request Update Application</DialogTitle>
                    <DialogContent>
                        <CardBody>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <div className="modal-body">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-address"
                                                >
                                                    Enter what you request
                                                </label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <div className="modal-body">
                                                <textarea className="form-control-alternative" name="" value={request} onChange={handleChangeRequest} cols="30" rows="5"></textarea>

                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRequestModal} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSendRequest}
                            color="primary"
                            type="button"
                        >
                            Change
                        </Button>
                    </DialogActions>
                </form>
            </Dialog> */}
            <div id="container">

                <Header />

                <Aside />

                <div id="main-content">
                    <div className="wrapper site-min-height">
                        <div className="row mt">
                            <div className="col-lg-12">
                                <div className="row content-panel">
                                    <div className="col-md-4 profile-text mt mb centered">
                                        <div className="right-divider hidden-sm hidden-xs">
                                            Status <h4>{jobUser?.status}</h4>
                                            <br />
                                            <h4>0</h4>
                                            <h6>Number Of Applications</h6>
                                            <h4>0</h4>
                                            <h6>Accepted Applications</h6>
                                            <h4>0</h4>
                                            <h6>Refused Applications</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-4 profile-text">
                                        <h3>{appliedUser?.username}</h3>
                                        <h6>{appliedUser?.first_name} {appliedUser?.last_name}</h6>
                                        <p>Some descriptionabout current user who is appling to the current job ...</p>

                                    </div>
                                    <div className="col-md-4 centered">
                                        <div className="profile-pic">
                                            <p><img src={MEDIA_URL_AVATAR + appliedUser?.avatar} className="img-circle" /> </p>
                                            <br />
                                            <p>
                                                {/*                                                 <button className="btn btn-theme" style={{ marginRight: '5px' }} onClick={() => setOpenAcceptModal(true)}> ACCEPT</button>
                                                <button className="btn btn-theme02" style={{ marginRight: '5px' }} onClick={() => setOpenRequestModal(true)}>REQUEST UPDATE</button>
                                                <button className="btn btn-danger" onClick={() => setOpenRejectModal(true)}>REFUSED</button> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt">
                                <div className="row content-panel">

                                    <div className="panel-body">
                                        <div className="tab-content">
                                            <div id="overview" className="tab-pane active">
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <div className="detailed">
                                                            <Collapsible trigger={<h4>Application Files</h4>}>
                                                                {jobUser !== null ?
                                                                    <div className="recent-activity">
                                                                        <div className="activity-panel" style={{ cursor: 'pointer', backgroundColor: ` ${activeFile === "CV" ? "#c2c1c0" : ""}` }} onClick={() => { showCurrentFile("CV") }}>
                                                                            <h5>CV</h5>
                                                                            <p>{jobUser?.cv.path}</p>
                                                                        </div>
                                                                        <div className="activity-panel mt" style={{ cursor: 'pointer', backgroundColor: ` ${activeFile === "MOTIVATION" ? "#c2c1c0" : ""}` }} onClick={() => { showCurrentFile("MOTIVATION") }}>
                                                                            <h5>MOTIVATION</h5>
                                                                            <p>{jobUser?.motivation_letter.path}</p>
                                                                        </div>
                                                                        <div className="activity-panel mt" style={{ cursor: 'pointer', backgroundColor: ` ${activeFile === "ID CARD" ? "#c2c1c0" : ""}` }} onClick={() => { showCurrentFile("ID CARD") }}>
                                                                            <h5>ID CARD</h5>
                                                                            <p>{jobUser?.id_card.path}</p>
                                                                        </div>
                                                                        {
                                                                            jobUser?.attachements?.map((item) =>
                                                                                <div key={item.id} className="activity-panel mt" style={{ cursor: 'pointer', backgroundColor: ` ${activeFile === item.name ? "#c2c1c0" : ""}` }} onClick={() => { showCurrentFile(item.name) }}>
                                                                                    <h5>{item.name}</h5>
                                                                                    <p>{item.path}</p>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                    : ''}
                                                            </Collapsible>
                                                            <Collapsible trigger={<h4>Update Requests</h4>}>
                                                                {requests !== null ?

                                                                    <div className="task-content">
                                                                        <ul id="sortable" className="task-list">
                                                                            {
                                                                                requests.map((item) =>
                                                                                    <div key={item.key} className="panel-body mt-3">
                                                                                        <div className="pull-left">
                                                                                            <span className="task-title-sp" style={{ marginRight: '5px' }}>{item.request}</span>
                                                                                            <span className="badge bg-theme">{item.status}</span>
                                                                                        </div>
                                                                                        <div className="pull-right">
                                                                                            <div>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    style={{ display: 'none' }}
                                                                                                    ref={fileInputRef}
                                                                                                    onChange={handleUploadFile} />
                                                                                                    {[2, "ANSWERED"].includes(item.status) ? <button  style={{ marginRight: '5px' }} disabled>Response</button>:
                                                                                                <button  style={{ marginRight: '5px' }} onClick={() => { setActiveRequest(item); handleClickInputFile()}}>Response</button>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                    </div>


                                                                                )}

                                                                        </ul>
                                                                    </div>
                                                                    : ''}
                                                            </Collapsible>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 detailed">
                                                        <h4>{activeFile}</h4>
                                                        {urlToImage !== "" ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js">
                                                            <div
                                                                style={{ height: "500px", border: "1px solid gray" }}
                                                            >
                                                                <Viewer
                                                                    initialPage={1}
                                                                    fileUrl={urlToImage}
                                                                    plugins={[highlightPluginInstance]}
                                                                />
                                                                <br />
                                                                <p style={{ textAlign: 'center' }}>
                                                                    <button className="btn btn-theme02" style={{ marginRight: '5px' }} type="button" onClick={() => setShown(true)}>VIEW</button>
                                                                    <a download ref={linkRef} href={urlToImage} ><button className="btn btn-primary" >DOWNLOAD</button></a>

                                                                </p>
                                                            </div>
                                                        </Worker> : ""}

                                                        {shown && ReactDOM.createPortal(modalBody(activeFile, urlToImage), document.body)}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationDetails;