import React, {useContext} from "react";
import {AuthContext} from "../context/AuthContext";

function Header() {
    const {logOut} = useContext(AuthContext);
    return (
        <div >
            <header className="header fixed-top black-bg">
                <div className="sidebar-toggle-box">
                    <div className="fa fa-bars tooltips" data-placement="right" data-original-title="Toggle Navigation"></div>
                </div>

                <a href="/"><img style={{width: '155px'}} src="client/img/logo-removebg.png" className="img-header-user-space"alt="" /></a>


                <div style={{float:'right'}} className="nav pull-right notify-row" id="top_menu">

                    <ul className="nav top-menu">
                        <li className="dropdown">
                            <a  href="/">
                                Back to home
                            </a>
                        </li>
                        <li className="dropdown">
                            <a  href="#" onClick={logOut}>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
{/*                 <div className="top-menu">
                    <ul className="nav pull-right top-menu">

                    <li ><a className="logout" href="/">Back to Home</a></li>
                        <li><a className="logout" onClick={logOut} href="#">Logout</a></li>
                    </ul>
                </div> */}
            </header>
        </div>
    );
}

export default Header;
