import React, { useState } from "react";
import GlobalHeader from "../components/GlobalHeader";
import { useForm } from "react-hook-form";
import { registration } from "../services/AuthServices";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import GlobalFooter from "../components/GlobalFooter";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Register(props) {

  const { register, handleSubmit, reset, formState: { errors }, } = useForm();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAcceptTerms(event.target.checked);
    console.log(acceptTerms);
  };

  const registerUser = async (data) => {
    if (!acceptTerms) {
      setAlertMessage("You must accept our tems and conditions !");
      setAlertType("error");
      handleOpenAlert();
    } else {
      try {
        await registration(data);
        console.log("registed sucessfully");
        reset();
        navigate("/login");
      } catch (error) {
        setAlertMessage(error.message);
        setAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };


  const removeSpace = (e) => {
    let text = e.target.value;
    let result = text.replace(/^\s+|\s+$/gm, ' ');
    e.target.value = result;
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <GlobalHeader />
      <main id="main">
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">


            <div className="section-title mt-5">
              <h2>Sign up here</h2>
              <p>Fill the following form to create an account on NTB Entreprise.</p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">

                <img src="client/img/register.png" className="img-fluid"
                  alt="" />

              </div>

              <div className="col-lg-6 mt-5">
                <form onSubmit={handleSubmit(registerUser)} className="php-email-form">
                  <div className="row">
                    <div className="col form-group">
                      <input type="text" className="form-control"
                        placeholder="First Name" {...register("first_name", { required: "First name required", })}
                        onChange={(e) => { removeSpace(e); }} required />
                    </div>
                    <div className="col form-group">
                      <input type="text" className="form-control"
                        placeholder="Last Name" {...register("last_name", {
                          required: "Last name required",
                        })}
                        onChange={(e) => {
                          removeSpace(e);
                        }} required />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col form-group">
                      <input type="text" className="form-control"
                        placeholder="User Name"  {...register("username", { required: "Username required", })}
                        onChange={(e) => { removeSpace(e); }} required />
                    </div>
                    <div className="col form-group">
                      <input type="email" className="form-control"
                        placeholder="Your Email" {...register("email", {
                          required: "Email name required",
                        })}
                        onChange={(e) => {
                          removeSpace(e);
                        }} required />
                    </div>
                  </div>

                  <div className="form-group">
                    <input type="password" className="form-control"
                      placeholder="Password"  {...register("password", {
                        required: "Password required",
                      })} required />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control"
                      placeholder="Confirm Password" {...register("password_confirmation", {
                        required: "Confirm password required",
                      })} required />
                  </div>
                  <div className="row">
                    <div className="col form-group">
                      <input type="checkbox" checked={acceptTerms} onChange={handleChange} /> I agree the <span style={{ color: '#f57b06' }}> Terms and Conditions</span>
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit">Sign Up</button>
                  </div>
                </form>

                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                  Already have an account ?<br />
                  <a className="" href="/login">
                    <span style={{ color: '#f57b06' }}>Sign in here</span>
                  </a>
                </div>
              </div>

            </div>


          </div>
        </section>
      </main>
      <GlobalFooter />

    </>

  );
}

export default Register;