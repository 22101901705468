import React from "react";
import GlobalHeader from "../../components/GlobalHeader";
import GlobalFooter from "../../components/GlobalFooter";

function Service() {

    return (
        <>
            <div style={{backgroundColor: "#fff"}}>
                <GlobalHeader/>

                <main id="main" style={{marginTop: '5%'}}>
                    <section id="services section-bg" className="services  ">
                        <div className="container" data-aos="fade-up">

                                <div className="content-panel" style={{marginBottom: "2%"}}>
                                    <h4><i className="fa fa-angle-right"></i> All services</h4>
                                </div>
                                {/*<h2>Services</h2>*/}
                                {/*<p>We can offer to you, different services related to computer science..</p>*/}

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="icon-box-job" data-aos="fade-up" data-aos-delay="100">
                                        <img src="client/img/logo.jpeg" className="img-fluid-beside"
                                             alt=""/>

                                        <h4><a href="#">Web site</a></h4>
                                        <p>We accompany you in the creation and hosting of your websites:
                                            Availability of personalized domain names.</p>
                                    </div>
                                </div> <div className="col-md-4">
                                    <div className="icon-box-job" data-aos="fade-up" data-aos-delay="100">
                                        <img src="client/img/logo.jpeg" className="img-fluid-beside"
                                             alt=""/>

                                        <h4><a href="#">Web site</a></h4>
                                        <p>We accompany you in the creation and hosting of your websites:
                                            Availability of personalized domain names.</p>
                                    </div>
                                </div> <div className="col-md-4">
                                    <div className="icon-box-job" data-aos="fade-up" data-aos-delay="100">
                                        <img src="client/img/logo.jpeg" className="img-fluid-beside"
                                             alt=""/>

                                        <h4><a href="#">Web site</a></h4>
                                        <p>We accompany you in the creation and hosting of your websites:
                                            Availability of personalized domain names.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>

               <GlobalFooter />

                <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i
                    className="bi bi-arrow-up-short"></i></a>

            </div>
        </>
    );
}

export default Service;