//url to media in the local backend
//export const MEDIA_URL = "http://127.0.0.1:8000/media/";
//export const MEDIA_URL_AVATAR = "http://127.0.0.1:8000/avatars/";
//export const MEDIA_API_URL = "http://127.0.0.1:8000/api/media/";

//url to media in the online backend
export const MEDIA_URL = "https://test.api.ntb-entreprise.de/media/";
export const MEDIA_URL_AVATAR = "https://test.api.ntb-entreprise.de/avatars/";
export const MEDIA_API_URL = "https://test.api.ntb-entreprise.de/api/media/";

