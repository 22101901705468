import React, { useState } from "react";
import GlobalHeader from "../components/GlobalHeader";
import { resent } from "../services/AuthServices";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalFooter from "../components/GlobalFooter";
import { useForm } from "react-hook-form";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function VerifyEmail() {

  const location = useLocation();
  const {register, handleSubmit, reset, formState: { errors }} = useForm();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const email = location.state?.email

  const resentLink = async () => {

    console.log(email);

    try {
      let result = await resent(email);

      setAlertMessage("Email resent sucessfully !");
      setAlertType("success");
      handleOpenAlert();

    } catch (error) {
      console.log(error.message);
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <GlobalHeader />
      <main id="main">
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">


            <div className="section-title mt-5">
              <h2>Verify Your Email</h2>
              <p>Please click on the link you received in your mails to verify.</p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">

                <img src="client/img/verify.png" className="img-fluid"
                  alt="" />

              </div>

              <div className="col-lg-6 mt-5">
                <form onSubmit={handleSubmit(resentLink)} className="php-email-form">

                  <div className="text-center">
                    <button type="submit">Resent Email Verification Link</button>
                  </div>
                </form>

                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                  Already have an account ?<br />
                  <a className="" href="/login">
                    <span style={{ color: '#f57b06' }}>Sign in here</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <GlobalFooter />
      <div className="row" style={{ margin: "15px 15px 0px 15px" }}>
        <div className="col-4 img-hide" style={{ width: "900px", height: "950px" }}>
          <img src="client/img/illustration.jpg" width="100%" height="90%" alt="" />
        </div>
        <div className="col-6 body-center forgot">
          <form className="form-login">
            <h2 className="form-login-heading">Please You<br />
              Must Verify Your Email</h2>
            <div className="login-wrap">
              {/*  <input type="text" className="form-control" placeholder="Email"/> */}
              <div style={{ marginTop: '2%' }}>
                {/*<input type="checkbox" value="remember-me"/> I agree the <span style={{fontWeight: 'bold'}}> Terms and Conditions</span>*/}
                <button className="btn btn-theme btn-block" type="button" onClick={resentLink}><i
                  className="fa fa-undo"></i> Resent Email Verification Link
                </button>
                <hr />
                {/*<div className="login-social-link centered">*/}
                {/*    <p>or you can sign in via your social network</p>*/}
                {/*    <button className="btn btn-facebook" type="submit"><i*/}
                {/*        className="fa fa-facebook"></i> Facebook*/}
                {/*    </button>*/}
                {/*    <button className="btn btn-twitter" type="submit"><i className="fa fa-twitter"></i> Twitter*/}
                {/*    </button>*/}
                {/*</div>*/}
              </div>
              <div className="registration">
                Go back to login page <a className="" href="/login">
                  <span style={{ color: '#f57b06' }}>Sign in</span>
                </a>
              </div>
            </div>

            <div aria-hidden="true" aria-labelledby="myModalLabel" role="dialog" tabIndex="-1" id="myModal"
              className="modal fade">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal"
                      aria-hidden="true">&times;</button>
                    <h4 className="modal-title">Forgot Password ?</h4>
                  </div>
                  <div className="modal-body">
                    <p>Enter your e-mail address below to reset your password.</p>
                    <input type="text" name="email" placeholder="Email" autoComplete="off"
                      className="form-control placeholder-no-fix" />
                  </div>
                  <div className="modal-footer">
                    <button data-dismiss="modal" className="btn btn-default" type="button">Cancel
                    </button>
                    <button className="btn btn-theme" type="button">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;