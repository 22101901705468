import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalHeader from "../components/GlobalHeader";
import { useForm } from "react-hook-form";
import { connection } from "../services/AuthServices";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AuthContext } from "../context/AuthContext";
import GlobalFooter from "../components/GlobalFooter";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const { afterLogin } = useContext(AuthContext);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();

    const loginUser = async (data) => {
        console.log(data);
        try {
            let result = await connection(data);

            setAlertMessage("connected sucessfully");
            setAlertType("success");
            handleOpenAlert();
            afterLogin();
            navigate("/");
        } catch (error) {
            if (error.status === 403) {
                let email = error.data.message;
                console.log(email);
                navigate('/verify-email',
                    { state: { email: email } }
                );
                //history.push("/verify-email", { email: email });
            } else {
                console.log(error.data.message);
                setAlertMessage(error.data.message);
                setAlertType("error");
                handleOpenAlert();
            }
        }
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <GlobalHeader />
            <main id="main">
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">


                        <div className="section-title mt-5">
                            <h2>Sign in now</h2>
                            <p>Fill in your credentials to access your user space.</p>
                        </div>
                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                            <div className="col-lg-6">

                                <img src="client/img/login.png" className="img-fluid"
                                    alt="" />

                            </div>

                            <div className="col-lg-6 mt-5">
                                <form onSubmit={handleSubmit(loginUser)} className="php-email-form">
                                    <div className="form-group">
                                        <input type="email" className="form-control" {...register("email")} autoFocus
                                            placeholder="Email" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control"  {...register("password")}
                                            placeholder="Password" required />
                                    </div>
                                    <div className="row">
                                        <div className="col form-group">
                                            <input type="checkbox" {...register('remember')} /> Remember me
                                        </div>
                                        <div className="col form-group">
                                            <span className="pull-right">
                                                <a href="/forgot-password"> Forgot Password?</a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button type="submit">Sign In</button>
                                    </div>
                                </form>

                                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                                    Don't have an account yet?<br />
                                    <a className="" href="/register">
                                        <span style={{ color: '#f57b06' }}>Create an account</span>
                                    </a>
                                </div>
                            </div>

                        </div>


                    </div>
                </section>
            </main>
            <GlobalFooter />
        </>

    );
}

export default Login;