import React, {useContext, useEffect, useState} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Aside from "../../components/Aside";
import {MEDIA_URL_AVATAR} from "../../constantes";
import {AuthContext} from "../../context/AuthContext";
import {getUserApplications} from "../../services/JobServices";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {registration, updateUser} from "../../services/AuthServices";
import {removeAvatarService} from "../../services/UserServices";


function Profile() {
    const [appliedJobs, setAppliedJobs] = useState([]);
    const {user, connected, afterLogin} = useContext(AuthContext);

    const {register, handleSubmit, reset, formState: {errors},} = useForm();
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();

    const handleChange = (event) => {
        setAcceptTerms(event.target.checked);
        console.log(acceptTerms);
    };

    const removeAvatar = async () => {
        try {
            await removeAvatarService(user?.id, null);
            afterLogin();
            setAlertMessage("Avatar successfully removed");
            setAlertType("success");
            handleOpenAlert();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    }

    const registerUser = async (data) => {
        console.log(data)
        try {
            await updateUser(data, user?.id);
            console.log("registed sucessfully");
            reset();
            navigate("/profile");
        } catch (error) {
            setAlertMessage(error.message);
            setAlertType("error");
            handleOpenAlert();
        }
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };


    const removeSpace = (e) => {
        let text = e.target.value;
        let result = text.replace(/^\s+|\s+$/gm, ' ');
        e.target.value = result;
    };


    useEffect(() => {
        const user_id = user?.id;
        const retrieveUserApplications = async () => {
            let data = await getUserApplications(user_id);
            let arr = Object.values(data);
            console.log(arr);
            setAppliedJobs(arr);
        }

        if (user) {
            retrieveUserApplications();
        }

    }, []);
    return (
        <div>
            <div id="container">

                <Header/>
                <Aside/>
                <div id="main-content">
                    <div className="wrapper site-min-height">
                        <div className="row mt">
                            <div className="col-lg-12">
                                <div className="row content-panel">
                                    <div className="col-md-1 profile-text mt mb centered">
                                        {/* <div className="right-divider hidden-sm hidden-xs">
                                            <h4>{(appliedJobs.filter(e => e.status === 'PENDING').length)}</h4>
                                            <h6>PENDING APPLICATIONS</h6>
                                            <h4>{(appliedJobs.filter(e => e.status === 'IN_PROGRESS').length)}</h4>
                                            <h6>APPLICATIONS IN PROGRESS</h6>
                                            <h4>{(appliedJobs.filter(e => e.status === 'ACCEPTED').length)}</h4>
                                            <h6>ACCEPTED APPLICATIONS</h6>
                                            <h4>{(appliedJobs.filter(e => e.status === 'REJECTED').length)}</h4>
                                            <h6>REJECTED APPLICATIONS</h6>
                                            <h4>{appliedJobs.length}</h4>
                                            <h6>TOTAL APPLICATIONS</h6>
                                        </div>*/}
                                    </div>
                                    <div className="col-md-6 profile-text">
                                        <h3>{user?.first_name + ' ' + user?.last_name}</h3><br/>
                                        <h4>About Me</h4>
                                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                                            roots in a piece of classical Latin literature from 45 BC.</p>
                                        <br/>
                                        <p>
                                            <button className="btn btn-theme"><i
                                                className="fa fa-envelope"></i> Send Message
                                            </button>
                                        </p>
                                    </div>
                                    <div className="col-md-5 centered">
                                        <div className="profile-pic">
                                            <p className="centered"><a data-bs-toggle="modal"
                                                                       data-bs-target="#myAvatarModal"><img
                                                src={MEDIA_URL_AVATAR + user?.avatar}/></a></p>
                                            <p>
                                                <a data-toggle="tab" href="#edit">
                                                    <button className="btn btn-theme03">Edit profile</button>
                                                </a>
                                                <button className="btn btn-theme04" style={{marginLeft: '10%'}}
                                                        data-bs-target="#confirmationRemoveCourseModal"
                                                        data-bs-toggle="modal"
                                                >Remove
                                                    avatar
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt">
                                <div className="row content-panel">
                                    {/* <div className="panel-heading">
                                        <ul className="nav nav-tabs nav-justified">
                                            <li className="active">
                                                <a data-toggle="tab" href="#overview">Overview</a>
                                            </li>
                                            <li>
                                                <a data-toggle="tab" href="#contact" className="contact-map">Contact</a>
                                            </li>
                                            <li>
                                                <a data-toggle="tab" href="#edit">Edit Profile</a>
                                            </li>
                                        </ul>
                                    </div>*/}
                                    <div className="panel-body">
                                        <div className="tab-content">
                                            <div id="overview" className="tab-pane active">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        {/*<textarea rows="3" className="form-control"*/}
                                                        {/*          placeholder="Whats on your mind?"></textarea>*/}
                                                        {/*<div className="grey-style">*/}
                                                        {/*    <div className="pull-left">*/}
                                                        {/*        <button className="btn btn-sm btn-theme"><i*/}
                                                        {/*            className="fa fa-camera"></i></button>*/}
                                                        {/*        <button className="btn btn-sm btn-theme"><i*/}
                                                        {/*            className="fa fa-map-marker"></i></button>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="pull-right">*/}
                                                        {/*        <button className="btn btn-sm btn-theme03">POST</button>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className="form-panel">
                                                            {/*<h4 className="mb"><i className="fa fa-angle-right"></i> Profile informations</h4>*/}
                                                            <form className="form-horizontal style-form" method="get">
                                                                <div className="form-group">
                                                                    <label
                                                                        className="col-sm-3 col-sm-3 control-label">Email</label>
                                                                    <label
                                                                        className="col-sm-2 col-sm-2 control-label">{user?.email}</label>
                                                                    {/*<div className="col-sm-10">*/}
                                                                    {/*    <input type="email" className="form-control"/>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        className="col-sm-3 col-sm-3 control-label">username</label>
                                                                    <label
                                                                        className="col-sm-2 col-sm-2 control-label">{user?.username}</label>
                                                                    {/*<div className="col-sm-10">*/}
                                                                    {/*    <input type="text" className="form-control"/>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="col-sm-3 col-sm-3 control-label">First
                                                                        name</label>
                                                                    <label
                                                                        className="col-sm-2 col-sm-2 control-label">{user?.first_name}</label>
                                                                    {/*<div className="col-sm-10">*/}
                                                                    {/*    <input type="text" className="form-control"/>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="col-sm-3 col-sm-3 control-label">Last
                                                                        name</label>
                                                                    <label
                                                                        className="col-sm-2 col-sm-2 control-label">{user?.last_name}</label>
                                                                    {/*<div className="col-sm-10">*/}
                                                                    {/*    <input type="text" className="form-control"/>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="col-sm-3 col-sm-3 control-label">Mobile
                                                                        Phone</label>
                                                                    <label
                                                                        className="col-sm-3 col-sm-2 control-label">{user?.phone}</label>
                                                                    {/*<div className="col-sm-10">*/}
                                                                    {/*    <input type="text" className="form-control"/>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                                {/*<div className="detailed mt">*/}
                                                                {/*    <h4>Recent Activity</h4>*/}
                                                                {/*    <div className="recent-activity">*/}
                                                                {/*        <div className="activity-icon bg-theme"><i*/}
                                                                {/*            className="fa fa-check"></i></div>*/}
                                                                {/*        <div className="activity-panel">*/}
                                                                {/*            <h5>1 HOUR AGO</h5>*/}
                                                                {/*            <p>Purchased: Dashio Admin Panel & Front-end*/}
                                                                {/*                theme.</p>*/}
                                                                {/*        </div>*/}
                                                                {/*        <div className="activity-icon bg-theme02"><i*/}
                                                                {/*            className="fa fa-trophy"></i></div>*/}
                                                                {/*        <div className="activity-panel">*/}
                                                                {/*            <h5>5 HOURS AGO</h5>*/}
                                                                {/*            <p>Task Completed. Resolved issue with Disk*/}
                                                                {/*                Space.</p>*/}
                                                                {/*        </div>*/}
                                                                {/*        <div className="activity-icon bg-theme04"><i*/}
                                                                {/*            className="fa fa-rocket"></i></div>*/}
                                                                {/*        <div className="activity-panel">*/}
                                                                {/*            <h5>3 DAYS AGO</h5>*/}
                                                                {/*            <p>Launched a new product: Flat Pack*/}
                                                                {/*                Heritage.</p>*/}
                                                                {/*        </div>*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5 detailed">
                                                        <h4>User Stats {appliedJobs.length}</h4>
                                                        <div className="row centered mt mb">
                                                            <div className="col-sm-3">
                                                                <h1><i className="fa fa-money"></i></h1>
                                                                <h3>{(appliedJobs.filter(e => e.status === 'PENDING').length)}</h3>
                                                                <h6>APPLICATIONS IN PROGRESS</h6>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h1><i className="fa fa-trophy"></i></h1>
                                                                <h3>{(appliedJobs.filter(e => e.status === 'IN_PROGRESS').length)}</h3>
                                                                <h6>COMPLETED TASKS</h6>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h1><i className="fa fa-shopping-cart"></i></h1>
                                                                <h3>{(appliedJobs.filter(e => e.status === 'ACCEPTED').length)}</h3>
                                                                <h6>ACCEPTED APPLICATIONS</h6>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h1><i className="fa fa-shopping-cart"></i></h1>
                                                                <h3>{(appliedJobs.filter(e => e.status === 'REJECTED').length)}</h3>
                                                                <h6>REJECTED APPLICATIONS</h6>
                                                            </div>
                                                        </div>
                                                        {/*<h4>My Friends</h4>*/}
                                                        {/*<div className="row centered mb">*/}
                                                        {/*    <ul className="my-friends">*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-01.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-02.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-03.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-04.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-05.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-06.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-07.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-08.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-09.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-10.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <div className="friends-pic"><img*/}
                                                        {/*                className="img-circle" width="35"*/}
                                                        {/*                height="35"*/}
                                                        {/*                src="assets/img/friends/fr-11.jpg"/></div>*/}
                                                        {/*        </li>*/}
                                                        {/*    </ul>*/}
                                                        {/*    <div className="row mt">*/}
                                                        {/*        <div className="col-md-4 col-md-offset-4">*/}
                                                        {/*            <h6><a href="#">VIEW ALL</a></h6>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {/*<h4>Pending Tasks</h4>*/}
                                                        {/*<div className="row centered">*/}
                                                        {/*    <div className="col-md-8 col-md-offset-2">*/}
                                                        {/*        <h5>Dashboard Update (40%)</h5>*/}
                                                        {/*        <div className="progress">*/}
                                                        {/*            <div className="progress-bar progress-bar-info"*/}
                                                        {/*                 role="progressbar" aria-valuenow="40"*/}
                                                        {/*                 aria-valuemin="0" aria-valuemax="100"*/}
                                                        {/*                 style={{width: '40%'}}>*/}
                                                        {/*                <span*/}
                                                        {/*                    className="sr-only">40% Complete (success)</span>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <h5>Unanswered Messages (80%)</h5>*/}
                                                        {/*        <div className="progress">*/}
                                                        {/*            <div className="progress-bar progress-bar-info"*/}
                                                        {/*                 role="progressbar" aria-valuenow="40"*/}
                                                        {/*                 aria-valuemin="0" aria-valuemax="100"*/}
                                                        {/*                 style={{width: '80%'}}>*/}
                                                        {/*                <span*/}
                                                        {/*                    className="sr-only">80% Complete (success)</span>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <h5>Product Review (60%)</h5>*/}
                                                        {/*        <div className="progress">*/}
                                                        {/*            <div className="progress-bar progress-bar-info"*/}
                                                        {/*                 role="progressbar" aria-valuenow="40"*/}
                                                        {/*                 aria-valuemin="0" aria-valuemax="100"*/}
                                                        {/*                 style={{width: '60%'}}>*/}
                                                        {/*                <span*/}
                                                        {/*                    className="sr-only">60% Complete (success)</span>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <h5>Friend Requests (90%)</h5>*/}
                                                        {/*        <div className="progress">*/}
                                                        {/*            <div className="progress-bar progress-bar-info"*/}
                                                        {/*                 role="progressbar" aria-valuenow="40"*/}
                                                        {/*                 aria-valuemin="0" aria-valuemax="100"*/}
                                                        {/*                 style={{width: '90%'}}>*/}
                                                        {/*                <span*/}
                                                        {/*                    className="sr-only">90% Complete (success)</span>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="contact" className="tab-pane">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div id="map"></div>
                                                    </div>
                                                    <div className="col-md-6 detailed">
                                                        <h4>Location</h4>
                                                        <div className="col-md-8 col-md-offset-2 mt">
                                                            <p>
                                                                Postal Address<br/> PO BOX 12988, Sutter
                                                                Ave<br/> Brownsville, New York.
                                                            </p>
                                                            <br/>
                                                            <p>
                                                                Headquarters<br/> 844 Sutter Ave,<br/> 9003, New
                                                                York.
                                                            </p>
                                                        </div>
                                                        <h4>Contacts</h4>
                                                        <div className="col-md-8 col-md-offset-2 mt">
                                                            <p>
                                                                Phone: +33 4898-4303<br/> Cell: 48 4389-4393<br/>
                                                            </p>
                                                            <br/>
                                                            <p>
                                                                Email: hello@dashiotheme.com<br/> Skype:
                                                                UseDashio<br/> Website: http://Alvarez.is
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="edit" className="tab-pane">
                                                <section id="contact" className="contact">
                                                    <div className="container" data-aos="fade-up">
                                                        <div className="section-title mt-5">
                                                            <h2>PERSONAL INFORMATIONS </h2>
                                                        </div>
                                                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                                                            <div className="col-lg-1">

                                                                <img src="client/img/register.png" className="img-fluid"
                                                                     alt=""/>

                                                            </div>

                                                            <div className="col-lg-10 mt-5">
                                                                <form onSubmit={handleSubmit(registerUser)}
                                                                      className="php-email-form">
                                                                    <div className="row">
                                                                        <div className="col form-group">
                                                                            <input type="text" className="form-control"
                                                                                   placeholder="First Name"
                                                                                   defaultValue={user?.first_name}
                                                                                   {...register("first_name", {required: "First name required",})}
                                                                                   onChange={(e) => {
                                                                                       removeSpace(e);
                                                                                   }} required/>
                                                                        </div>
                                                                        <div className="col form-group">
                                                                            <input type="name" className="form-control"
                                                                                   placeholder="Last Name"
                                                                                   defaultValue={user?.last_name}
                                                                                   {...register("last_name", {
                                                                                       required: "Last name required",
                                                                                   })}
                                                                                   onChange={(e) => {
                                                                                       removeSpace(e);
                                                                                   }} required/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col form-group">
                                                                            <input type="text" className="form-control"
                                                                                   placeholder="User Name"
                                                                                   defaultValue={user?.username}
                                                                                   {...register("username", {required: "Username required",})}
                                                                                   onChange={(e) => {
                                                                                       removeSpace(e);
                                                                                   }} required/>
                                                                        </div>
                                                                        <div className="col form-group">
                                                                            <input type="email" className="form-control"
                                                                                   placeholder="Your Email"
                                                                                   defaultValue={user?.email}
                                                                                   {...register("email", {
                                                                                       required: "Email name required",
                                                                                   })}
                                                                                   onChange={(e) => {
                                                                                       removeSpace(e);
                                                                                   }}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col form-group">
                                                                            <input type="phone" className="form-control"
                                                                                   placeholder="Your phone"
                                                                                   defaultValue={user?.phone}
                                                                                   {...register("phone", )}
                                                                                   onChange={(e) => {
                                                                                       removeSpace(e);
                                                                                   }}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col form-group">
                                                                            <input type="phone" className="form-control"
                                                                                   placeholder="Your address"
                                                                                   defaultValue={user?.address}
                                                                                   {...register("address", )}
                                                                                   onChange={(e) => {
                                                                                       removeSpace(e);
                                                                                   }}/>
                                                                        </div>
                                                                    </div>

                                                                    {/*<div className="form-group">*/}
                                                                    {/*    <textarea className="form-control"*/}
                                                                    {/*              rows="5"*/}
                                                                    {/*              placeholder="About me"  {...register("about_me",)} />*/}
                                                                    {/*</div>*/}

                                                                    <div className="text-center">
                                                                        <button type="submit">Save</button>
                                                                    </div>
                                                                </form>
                                                            </div>

                                                        </div>

                                                      {/*  <div className="section-title mt-5">
                                                            <h2>PASSWORD</h2>
                                                        </div>
                                                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                                                            <div className="col-lg-1">

                                                                <img src="client/img/register.png" className="img-fluid"
                                                                     alt=""/>

                                                            </div>

                                                            <div className="col-lg-10 mt-5">
                                                                <form onSubmit={handleSubmit(registerUser)}
                                                                      className="php-email-form">
                                                                    <div className="row">
                                                                        <div className="form-group">
                                                                            <input type="password"
                                                                                   className="form-control"
                                                                                   placeholder="Password"  {...register("password", {
                                                                                required: "Password required",
                                                                            })} required/>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <input type="password"
                                                                                   className="form-control"
                                                                                   placeholder="Confirm Password" {...register("password_confirmation", {
                                                                                required: "Confirm password required",
                                                                            })} required/>
                                                                        </div>

                                                                        <div className="text-center">
                                                                            <button type="submit">Save</button>
                                                                            <button type="reset"
                                                                                    className="btn btn-theme04"
                                                                                    style={{marginLeft: '10%'}}>Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>

                                                        </div>*/}


                                                    </div>
                                                </section>
                                                {/*<div className="row">*/}
                                                {/*    <div className="col-lg-2 col-lg-offset-2 detailed">*/}
                                                {/*    </div>*/}
                                                {/*    <div className="col-lg-8 col-lg-offset-2 detailed">*/}
                                                {/*        <h4 className="mb">Personal Information</h4>*/}
                                                {/*        <form role="form" className="form-horizontal">*/}
                                                {/*            <div className="row">*/}
                                                {/*                <div className="col-lg-2 ">*/}
                                                {/*                    Country*/}
                                                {/*                </div>*/}
                                                {/*                <div className="col-lg-10 ">*/}
                                                {/*                    <input type="text" placeholder=" " id="country"*/}
                                                {/*                           className="form-control"/>*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*             <div className="form-group">*/}
                                                {/*                <div className="row">*/}
                                                {/*                    <div className="col-lg-2 ">*/}
                                                {/*                        Country*/}
                                                {/*                    </div>*/}
                                                {/*                    <div className="col-lg-10 ">*/}
                                                {/*                        <input type="text" placeholder=" " id="country"*/}
                                                {/*                               className="form-control"/>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*            <div className="form-group">*/}
                                                {/*               <div className="row">*/}
                                                {/*                   <div className="col-lg-2 ">*/}
                                                {/*                       Description*/}
                                                {/*                   </div>*/}
                                                {/*                   <div className="col-lg-10 ">*/}
                                                {/*                       <textarea rows="10" cols="30"*/}
                                                {/*                                 className="form-control" id=""*/}
                                                {/*                                 name=""></textarea>*/}
                                                {/*                   </div>*/}
                                                {/*               </div>*/}

                                                {/*            </div>*/}
                                                {/*        </form>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="row">*/}
                                                {/*        <div className="col-lg-2 col-lg-offset-2 detailed mt">*/}
                                                {/*        </div>*/}
                                                {/*        <div className="col-lg-8 col-lg-offset-2 detailed mt">*/}
                                                {/*            <h4 className="mb">Password and security</h4>*/}
                                                {/*            <form role="form" className="form-horizontal">*/}
                                                {/*                <div className="form-group">*/}
                                                {/*                    <label className="col-lg-2 control-label">Address*/}
                                                {/*                        1</label>*/}
                                                {/*                    <div className="col-lg-6">*/}
                                                {/*                        <input type="text" placeholder=" " id="addr1"*/}
                                                {/*                               className="form-control"/>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*                <div className="form-group">*/}
                                                {/*                    <label className="col-lg-2 control-label">Address*/}
                                                {/*                        2</label>*/}
                                                {/*                    <div className="col-lg-6">*/}
                                                {/*                        <input type="text" placeholder=" " id="addr2"*/}
                                                {/*                               className="form-control"/>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*                <div className="form-group">*/}
                                                {/*                    <label*/}
                                                {/*                        className="col-lg-2 control-label">Phone</label>*/}
                                                {/*                    <div className="col-lg-6">*/}
                                                {/*                        <input type="text" placeholder=" " id="phone"*/}
                                                {/*                               className="form-control"/>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*                <div className="form-group">*/}
                                                {/*                    <label*/}
                                                {/*                        className="col-lg-2 control-label">Cell</label>*/}
                                                {/*                    <div className="col-lg-6">*/}
                                                {/*                        <input type="text" placeholder=" " id="cell"*/}
                                                {/*                               className="form-control"/>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*                <div className="form-group">*/}
                                                {/*                    <label*/}
                                                {/*                        className="col-lg-2 control-label">Email</label>*/}
                                                {/*                    <div className="col-lg-6">*/}
                                                {/*                        <input type="text" placeholder=" " id="email"*/}
                                                {/*                               className="form-control"/>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*                <div className="form-group">*/}
                                                {/*                    <label*/}
                                                {/*                        className="col-lg-2 control-label">Skype</label>*/}
                                                {/*                    <div className="col-lg-6">*/}
                                                {/*                        <input type="text" placeholder=" " id="skype"*/}
                                                {/*                               className="form-control"/>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*                <div className="form-group">*/}
                                                {/*                    <div className="col-lg-offset-2 col-lg-10">*/}
                                                {/*                        <button className="btn btn-theme"*/}
                                                {/*                                type="submit">Save*/}
                                                {/*                        </button>*/}
                                                {/*                        <button className="btn btn-theme04"*/}
                                                {/*                                type="button">Cancel*/}
                                                {/*                        </button>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </form>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<section id="main-content">*/}
                {/*    <section className="wrapper site-min-height"  style={{marginLeft: '1%'}}>*/}
                {/*        <div className="row mt">*/}
                {/*            <div className="col-lg-4">*/}
                {/*                <div className="row content-panel">*/}
                {/*                    <div className="col-md-10 centered">*/}
                {/*                        <div className="profile-pic">*/}
                {/*                            <p className="centered"><a data-bs-toggle="modal"*/}
                {/*                                                       data-bs-target="#myAvatarModal"><img*/}
                {/*                                src={MEDIA_URL_AVATAR + user?.avatar} className="img-circle" width="100"/></a></p>*/}
                {/*                            <h5>About Me</h5>*/}
                {/*                            <span>I'm a young an enthousiat researcher and programmer in the filed of cybersecurity today*/}
                {/*                            and dually in the frontend programming languages like react Js and angular Js</span>*/}

                {/*                            <p style = {{padding:'20px'}}>*/}
                {/*                                <button className="btn btn-theme" style = {{display : 'inline-block', marginRight:'50px'}}>Follow*/}
                {/*                                </button>*/}
                {/*                                <button className="btn btn-theme02" style = {{display : 'inline-block'}}>Add</button>*/}
                {/*                            </p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    /!*<div className="col-md-4 profile-text mt mb centered">*!/*/}
                {/*                    /!*    <div className="right-divider hidden-sm hidden-xs">*!/*/}
                {/*                    /!*        <h4>1922</h4>*!/*/}
                {/*                    /!*        <h6>FOLLOWERS</h6>*!/*/}
                {/*                    /!*        <h4>290</h4>*!/*/}
                {/*                    /!*        <h6>FOLLOWING</h6>*!/*/}
                {/*                    /!*        <h4>$ 13,980</h4>*!/*/}
                {/*                    /!*        <h6>MONTHLY EARNINGS</h6>*!/*/}
                {/*                    /!*    </div>*!/*/}
                {/*                    /!*</div>*!/*/}
                {/*                    /!*<div className="col-md-4 profile-text">*!/*/}
                {/*                    /!*    <h3>Sam Soffes</h3>*!/*/}
                {/*                    /!*    <h6>Main Administrator</h6>*!/*/}
                {/*                    /!*    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has*!/*/}
                {/*                    /!*        roots in a piece of classical Latin literature from 45 BC.</p>*!/*/}
                {/*                    /!*    <br/>*!/*/}
                {/*                    /!*    <p>*!/*/}
                {/*                    /!*        <button className="btn btn-theme"><i*!/*/}
                {/*                    /!*            className="fa fa-envelope"></i> Send Message*!/*/}
                {/*                    /!*        </button>*!/*/}
                {/*                    /!*    </p>*!/*/}
                {/*                    /!*</div>*!/*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-lg-8">*/}
                {/*                <div className="form-panel">*/}
                {/*                    /!*<h4 className="mb"><i className="fa fa-angle-right"></i> Profile informations</h4>*!/*/}
                {/*                    <form className="form-horizontal style-form" method="get">*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label className="col-sm-3 col-sm-3 control-label">Email</label>*/}
                {/*                            <label className="col-sm-2 col-sm-2 control-label">{user?.email}</label>*/}
                {/*                            /!*<div className="col-sm-10">*!/*/}
                {/*                            /!*    <input type="email" className="form-control"/>*!/*/}
                {/*                            /!*</div>*!/*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label className="col-sm-3 col-sm-3 control-label">username</label>*/}
                {/*                            <label className="col-sm-2 col-sm-2 control-label">{user?.username}</label>*/}
                {/*                            /!*<div className="col-sm-10">*!/*/}
                {/*                            /!*    <input type="text" className="form-control"/>*!/*/}
                {/*                            /!*</div>*!/*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label className="col-sm-3 col-sm-3 control-label">First name</label>*/}
                {/*                            <label className="col-sm-2 col-sm-2 control-label">{user?.first_name}</label>*/}
                {/*                            /!*<div className="col-sm-10">*!/*/}
                {/*                            /!*    <input type="text" className="form-control"/>*!/*/}
                {/*                            /!*</div>*!/*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label className="col-sm-3 col-sm-3 control-label">Last name</label>*/}
                {/*                            <label className="col-sm-2 col-sm-2 control-label">{user?.last_name}</label>*/}
                {/*                            /!*<div className="col-sm-10">*!/*/}
                {/*                            /!*    <input type="text" className="form-control"/>*!/*/}
                {/*                            /!*</div>*!/*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label className="col-sm-3 col-sm-3 control-label">Mobile Phone</label>*/}
                {/*                            <label className="col-sm-3 col-sm-2 control-label">{user?.phone}</label>*/}
                {/*                            /!*<div className="col-sm-10">*!/*/}
                {/*                            /!*    <input type="text" className="form-control"/>*!/*/}
                {/*                            /!*</div>*!/*/}
                {/*                        </div>*/}
                {/*                        /!*<div className="form-group">*!/*/}
                {/*                        /!*    <div className="col-lg-offset-2 col-lg-10">*!/*/}
                {/*                        /!*        <button className="btn btn-theme" type="submit">Submit</button>*!/*/}
                {/*                        /!*    </div>*!/*/}
                {/*                        /!*</div>*!/*/}

                {/*                    </form>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </section>*/}
                {/*</section>*/}

                {/* <Footer/> */}
                <div
                    className="modal fade"
                    id="confirmationRemoveCourseModal"
                    tabIndex="-1"
                    aria-labelledby="confirmationRemoveCourseModal"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"
                                    id="exampleModalLabel">
                                    Dissociate course
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                Do you want to remove really want to remove your avatar ?
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-danger  btn-block"
                                    onClick = {removeAvatar}
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Confirm
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-block"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>

            </div>
        </div>
    );
}

export default Profile;