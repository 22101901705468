import React, { useState } from "react";
import { useForm } from "react-hook-form";
import GlobalHeader from "../components/GlobalHeader";
import { forgot } from "../services/UserServices";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import GlobalFooter from "../components/GlobalFooter";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ForgotPassword() {
  const {register, handleSubmit, reset, formState: { errors }} = useForm();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  //const connected_user = JSON.parse(localStorage.getItem('connected_user'));

  const forgotPassword = async (data) => {
    console.log(data);
    try {
      let result = await forgot(data);

      setAlertMessage("Password reset link send successfully !");
      setAlertType("success");
      handleOpenAlert();
      reset();
      //afterLogin();
      //props.history.push("/");

    } catch (error) {
      console.log(error.message);
      setAlertMessage(error.message);
      setAlertType("error");
      handleOpenAlert();
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
          <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <GlobalHeader />
      <main id="main">
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">


            <div className="section-title mt-5">
              <h2>Forgot Password ?</h2>
              <p>Please enter your email then submit to get a password Reset Link.</p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">

                <img src="client/img/forgot.png" className="img-fluid"
                  alt="" />

              </div>

              <div className="col-lg-6 mt-5">
                <form onSubmit={handleSubmit(forgotPassword)} className="php-email-form">
                  <div className="form-group">
                    <input type="email" className="form-control" {...register("email")} autoFocus
                      placeholder="Please entrer your Email" required />
                  </div>

                  <div className="text-center">
                    <button type="submit">Send Link</button>
                  </div>
                </form>

                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                  Already have an account ?<br />
                  <a className="" href="/login">
                    <span style={{ color: '#f57b06' }}>Sign in here</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <GlobalFooter />
    </>
  );
}

export default ForgotPassword;